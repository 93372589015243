@import './colors';
@import './variables';

//===============
// generic mixins
//===============
@mixin mobile {
  @media screen and (orientation: portrait) and (max-width: 5in),
    screen and (orientation: landscape) and (max-width: 8in) {
    @content;
  }
}

@mixin narrow {
  @media screen and (max-width: 1125px) {
    @content;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin main-panel {
  width: $main-panel-width;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin fab-icon-plus($delay: 0.25s) {
  transition: transform $delay $default-transition-fn;

  &.open {
    transform: rotate(135deg);
  }
}

@mixin color-modifier($color) {
  &.#{$color} {
    color: map-get($colors, $color);
  }
}

@mixin background-modifier($color) {
  &.#{$color} {
    background-color: map-get($colors, $color);
  }
}

@mixin button-hover-modifier {
  &:hover {
    background-color: $hover-grey;
  }
}

@mixin children-margin($space: 0.875rem, $dir: right, $selector: '*') {
  & > #{$selector} {
    margin-#{$dir}: $space;
    @if $dir == right or $dir == bottom {
      &:last-child {
        margin-#{$dir}: 0;
      }
    } @else {
      &:first-child {
        margin-#{$dir}: 0;
      }
    }
  }
}

@mixin path-fill($color: currentColor) {
  path:not([fill='none']) {
    fill: $color;
  }
}

//======================
// mixins for components
//======================
@mixin paper {
  // box-shadow: $light-grey 1px 1px 5px 0px;
  background-color: $white;
}

@keyframes dialog-slide-in {
  0% {
    margin-top: 187.5rem;
  }
  100% {
    margin-top: unset; // bring to visible region
  }
}

@keyframes dialog-slide-out {
  0% {
    margin-top: unset;
  }
  100% {
    margin-top: 187.5rem; // bring out of visible region
  }
}

@mixin dialog {
  background: $white;
  box-shadow: $dark-shadow;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-std;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  width: 43.75rem;
  @content;
  // styles below this should not be overridden
  margin-top: 187.5rem; // out of visible region

  &.open {
    animation: dialog-slide-in 300ms $default-transition-fn forwards;
  }

  &.closing {
    animation: dialog-slide-out 300ms $default-transition-fn forwards;
  }

  &.mobile,
  &.full-screen {
    border-radius: 0;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  &.mobile {
    margin-left: 0;
    margin-right: 0;
  }
}
