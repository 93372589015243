@import '../../core/colors';

$animation-time: 150ms;
$border-radius: 4px;

$border-width: 6px;

$pip-control-zindex: 10;

.pip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  user-select: none;
  z-index: $z-vcall-frame;
  background-color: $black;

  &:not(.moving) {
    transition: top $animation-time ease, left $animation-time ease;
  }

  &.pip {
    border-radius: $border-radius;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  &.embedded {
    z-index: unset;
    transition: unset;
    cursor: default;

    @include mobile {
      z-index: $z-vcall-frame;
    }
  }

  &__controls {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    z-index: $pip-control-zindex;
    background-color: rgba(0, 0, 0, 0.1);
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__button {
    width: 2rem;
    max-height: 2rem;
    display: inline-block;
    color: $white;
    cursor: pointer;

    &.draggable {
      cursor: move;
      padding: 2px;
    }
  }

  &__footer {
    height: 48px;
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    font-size: 1rem;
  }

  &__body {
    border: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: width $animation-time ease, height $animation-time ease;
  }

  &.moving &__body {
    pointer-events: none;
  }

  &__top-edge,
  &__bottom-edge {
    position: absolute;
    width: calc(100% - 2 * #{$border-width});
    height: $border-width;
    left: $border-width;
    cursor: ns-resize;
    z-index: $pip-control-zindex;
  }

  &__top-edge {
    top: 0;
  }

  &__bottom-edge {
    bottom: 0;
  }

  &__left-edge,
  &__right-edge {
    position: absolute;
    width: $border-width;
    height: calc(100% - 2 * #{$border-width});
    top: $border-width;
    cursor: ew-resize;
    z-index: $pip-control-zindex;
  }

  &__left-edge {
    left: 0;
  }

  &__right-edge {
    right: 0;
  }

  &__top-left-corner,
  &__top-right-corner {
    position: absolute;
    width: $border-width;
    height: $border-width;
    top: 0;
    z-index: $pip-control-zindex;
  }

  &__top-left-corner {
    left: 0;
    cursor: nwse-resize;
  }

  &__top-right-corner {
    right: 0;
    cursor: nesw-resize;
  }

  &__bottom-left-corner,
  &__bottom-right-corner {
    position: absolute;
    width: $border-width;
    height: $border-width;
    bottom: 0;
    z-index: $pip-control-zindex;
  }

  &__bottom-left-corner {
    left: 0;
    cursor: nesw-resize;
  }

  &__bottom-right-corner {
    right: 0;
    cursor: nwse-resize;
  }
}
