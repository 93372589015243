.getin-screen {
  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375rem;
  }
}

.thank-you-screen {
  &__here {
    display: flex;
    align-items: center;
    padding: 2px 12px;
    margin: 1rem 0;
    border-radius: 8px;
    border: 1px solid #068f68;
    box-shadow: 0 1px 1px 1px rgba(6, 143, 104, 0.5);
    text-align: left;
    color: #068f68;
    word-break: break-word;

    &:hover {
      box-shadow: 0 1px 3px 1px rgba(6, 143, 104, 0.8);
    }
  }
}
