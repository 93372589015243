@import '../../core/mixins';
@import '../../core/variables';

.attempt-page {
  &__avatar {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-right: 0.4375em;
  }

  &__raised-button {
    visibility: hidden;
    width: 100%;
  }

  &__attempt-button {
    @include paper;
    padding: 0.75rem 0.875rem;
    cursor: pointer;
    text-align: center;
    width: $main-panel-width;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: $default-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    margin: 1.5rem 1rem 0.75rem;
    text-transform: uppercase;
    font-weight: bold;

    &.manual-stop {
      justify-content: center;
    }

    &.disabled {
      color: $grey;
      opacity: 0.75;
      text-transform: unset;
    }
  }

  &__timer {
    font-size: 14px;
    position: absolute;
    top: 0;
    background-color: $lightest-grey;
  }

  &__attempt-navigation {
    @include paper;
    box-shadow: $default-shadow;
    width: $main-panel-width;
    display: flex;
    padding: 0.4375rem 0.875rem;
    text-align: center;
    color: $red;
    bottom: 0;
    left: 50%;
    min-height: 1.75rem;
    transform: translateX(-50%);
    position: absolute;

    &.ipad,
    &.mobile {
      width: 100%;
      position: fixed;
    }
  }

  &__options {
    @include paper;
    margin-top: 0.4375rem;
  }

  &__attempt-header.mobile {
    @include paper;
    width: 100%;
    padding: 0.875rem 0;
    margin-bottom: 0.4375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.reorder-option {
  &__move {
    display: flex;
    align-items: center;

    .up,
    .down {
      width: 24px;
      height: auto;
      margin-right: 0.5rem;
      border-radius: 4px;
      border: 1px solid currentColor;

      &:not(:disabled) {
        color: $blue;
        cursor: pointer;
        border-color: $blue;
      }
    }
  }
}
