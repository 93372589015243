@import '../../core/colors';
@import '../../core/mixins';

$response-box-height: 5.845rem;

.comments {
  width: 100%;
  height: calc(100% - #{$response-box-height});
  flex: 1;
  display: flex;
  flex-direction: column;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    width: 100%;
    overflow: hidden;
    text-align: center;
    color: $light-grey;
    padding: 0.875rem;
  }

  &__list {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &__loader {
      @include center;
      width: 100%;
      height: 2.625rem;
      background-color: $white;
    }
  }

  &__load-older {
    margin: 0.5rem auto;
    max-width: 70%;
    font-size: 0.75rem;
    color: $blue;
  }

  &__no-more-message {
    margin: 0.5rem auto;
    max-width: 70%;
    font-size: 0.9rem;
    text-align: center;
    color: $light-grey;
  }

  &__tooltip {
    position: absolute;
    border-radius: 0.2rem;
    padding: 0.4375rem 0.875rem;
    top: 7rem;
    z-index: $z-tooltip;
    background-color: $light-black;
    color: $white;
    display: none;

    &.show {
      display: unset;
    }
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375em;
  }

  &__image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: $z-image-preview;
    background-color: $overlay-grey;

    &__close {
      color: $white;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      width: 2.5rem;
      height: 2.5rem;
      top: 1rem;
      right: 1rem;
      border-radius: 50%;
    }

    &__loader {
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    &__loader-wrapper {
      align-items: center;
      display: none;
    }

    &.loading &__loader-wrapper {
      display: block;
    }

    &__message {
      padding: 1rem;
    }

    &__image {
      max-width: 100%;
      max-height: 100%;
    }

    &.loading &__image {
      visibility: hidden;
    }
  }

  &__response-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    min-height: $response-box-height;
    padding: 0.4375em;
    border-top: 1px solid $another-grey;
  }

  &__sender {
    display: flex;
  }

  &__send-button {
    color: $blue;
    font-size: $font-lg;
    cursor: pointer;
    padding-top: 0.5rem;
    margin-left: auto;

    &-loader {
      padding: 0.5rem;
    }
  }

  &__send-options {
    display: flex;
    padding: 0.5rem;
  }

  &__send-option {
    color: $another-grey;
    cursor: pointer;
    font-size: $font-xlg;
    margin-right: 1rem;

    &:hover {
      color: $blue;
    }
  }

  &__new-messages {
    border-radius: 1000px;

    &-wrapper {
      background-color: $white;
      padding: 0.5rem;
      text-align: center;
    }

    button {
      cursor: pointer;
      border: none;
      box-shadow: none;
      color: $white;
      background-color: $blue;
      font-size: $font-sm;
      line-height: 28px;

      &:hover {
        background-color: #164d7a;
      }
    }

    &-text {
      flex: 1;
      padding-left: 0.75rem;
      padding-right: 0.375rem;
      border-top-left-radius: 1000px;
      border-bottom-left-radius: 1000px;
    }

    &-close {
      padding-left: 0.375rem;
      padding-right: 0.75rem;
      font-size: 1.25rem;
      line-height: 28px;
      border-top-right-radius: 1000px;
      border-bottom-right-radius: 1000px;

      &::after {
        content: '\00d7';
      }
    }
  }
}
