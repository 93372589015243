@import '../../core/mixins';
@import '../../core/variables';

$spacing: 0.5rem;
$cell-padding: $spacing 2 * $spacing;

%separator {
  border-bottom: 1px solid $lightest-grey;
}

%edit-button {
  color: $light-grey;
  cursor: pointer;
  align-self: center;
  padding: $spacing;
  margin-right: -$spacing;
  &:hover {
    color: $blue;
  }
}

%delete-button {
  color: $red;
  cursor: pointer;
  font-size: $font-lg;
  padding: $spacing;
  margin-right: -$spacing;
  margin-left: auto;
  align-self: center;
  flex-shrink: 0;
}

%cell {
  background-color: $white;
  padding: $cell-padding;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightest-grey;

  &.read-only {
    background-color: transparent;
  }
}

.info {
  display: flex;
  height: 100%;
  flex-direction: column;

  @include mobile {
    &__wrapper {
      padding: 0 $spacing;
    }
  }

  &__tab-padding {
    padding-top: $tab-height;
    @include mobile {
      padding-top: $tab-height-mobile;
    }
  }

  &__title {
    font-size: $font-lg;
    padding: 0.4375em 0.875rem;
  }

  &__date {
    padding: 0 0 0.875rem 0.875rem;
  }

  &__card {
    background: $white;
    margin-bottom: 0.875rem;
    padding-bottom: 0.875rem;
  }

  &__card-header {
    padding: 0.5rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    font-size: $font-lg;
    color: $grey;

    i {
      @extend %edit-button;
    }
  }

  &__card-content {
    padding: $cell-padding;
  }

  &__list-item {
    padding-bottom: 0.4375rem;
    padding: $cell-padding;
    color: $light-grey;
    @extend %separator;
  }

  &__links {
    padding: 0 0.5rem;
  }

  &__link {
    padding: $spacing 0;
    display: flex;
    text-decoration: none;
    color: $black;
    @extend %separator;

    &:last-child {
      border-bottom: none;
    }

    &.cell {
      @extend %cell;
    }

    &__details {
      flex: 1;
    }

    &__url {
      color: $blue;
      font-size: $font-sm;
      margin-top: 0.4375rem;
      word-break: break-all;
    }

    &__icon {
      @extend %delete-button;
    }
  }

  &__book {
    display: flex;
    padding: $spacing;
    @extend %separator;

    &.cell {
      @extend %cell;
      cursor: pointer;
    }

    &__details {
      flex: 1;
    }

    &__author {
      margin-top: 0.5em;
      color: $light-grey;
      text-decoration: italic;
    }

    &__isbn {
      margin-top: 0.4375rem;
      color: $light-grey;
    }

    &__remarks {
      align-items: flex-end;
      display: flex;
    }

    &.cell &__remarks {
      margin-right: 1rem;
    }

    i {
      @extend %edit-button;
      font-size: $font-lg;
    }
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375rem;
  }

  &__team-member {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: $spacing 0;

    &.cell {
      @extend %cell;
    }

    &__icon {
      @extend %delete-button;
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-self: stretch;
      flex: 1;
    }

    &__status {
      font-size: $font-sm;
      color: $light-grey;
    }
  }

  &__add-button {
    cursor: pointer;
    padding: 2 * $spacing;
    font-size: $font-sm;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;

    &__action {
      font-size: $font-md;
    }
  }

  &__office-hours {
    padding: $spacing 0;

    &:last-child {
      border-bottom: unset;
    }

    &__delete {
      @extend %delete-button;
    }

    &__venue {
      font-size: $font-sm;
      color: $light-grey;
      margin-top: 0.4375rem;
    }

    &__cell-wrapper {
      @extend %separator;
    }

    &__cell {
      @extend %cell;
      padding: 2 * $spacing;

      &.no-border {
        border: none;
      }
    }

    &__inconvenient {
      color: $orange;
      font-size: $font-sm;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      text-align: right;
    }

    &__venue-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2 * $spacing;
    }

    &__venue-label {
      margin-right: 0.5rem;
    }

    &__venue-marker {
      margin-left: 1rem;
      font-size: 1rem;
      color: $blue;
    }
  }

  &__extend-dialog {
    &__cell {
      @extend %cell;
      padding: 2 * $spacing;
    }

    &__note {
      font-size: $font-sm;
      color: $light-grey;
      display: flex;
      padding: $cell-padding;

      i {
        margin-right: 0.5rem;
        margin-top: 0.4375rem;
      }
    }
  }

  &__reading-list {
    &__heading {
      padding: $cell-padding;
      color: $grey;
    }

    &__file-view {
      padding: $cell-padding;
      background: $white;
      @extend %separator;
    }

    &__delete {
      @extend %delete-button;
      margin: 0;
    }
  }

  &__day-picker {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: $cell-padding;
    flex-wrap: wrap;
    @extend %separator;

    &__label {
      @extend %cell;
      border: none;
    }

    &__option {
      flex: 1;
      color: $blue;
      background-color: transparent;
      text-align: center;
      padding: 0.4375rem;
      cursor: pointer;
      text-transform: capitalize;

      &.selected {
        color: $white;
        background-color: $blue;
      }
    }
  }

  &__add-member {
    &__title {
      font-size: $font-lg;
      color: $green;
    }

    &__label {
      color: $blue;
      margin-top: 0.875rem;
      font-size: 0.65625rem;
    }

    &__note {
      color: $light-grey;
      font-size: 0.65625rem;
    }
  }
}
