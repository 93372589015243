@import '../../core/variables';

.content-view {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__main-panel {
    margin: 0 auto;
    width: 100%;
    max-width: $main-panel-width;
  }
}
