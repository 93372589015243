@import '../../core/colors';

$progress-bar-height: 0.25rem;

.progress-bar {
  width: 0%;
  min-width: 1px;
  max-width: 100%;
  height: 100%;
  background-color: $blue;
  transition: width 0.2s;

  &__container {
    width: 100%;
    height: $progress-bar-height;
    background-color: $light-grey;
    border-radius: $progress-bar-height;
    overflow: hidden;
  }
}
