@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.course-analytics {
  height: 100%;
  width: 100%;

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.875rem;
  }

  &__raised-button {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
    color: $red;
  }
}
