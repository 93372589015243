$animation-time: 0.25s;
$height: 40px;
$spacing: 20px;

#toast-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-toast;
}

.toast {
  padding: 0 1rem;
  border-radius: 4px;
  background-color: rgba($color: $black, $alpha: 0.75);
  color: $white;
  font-size: $font-md;
  height: $height;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: $spacing;
  opacity: 1;
  transition: $animation-time;
  transform: translate(-50%, 0%);
  max-width: 70%;
  pointer-events: none;

  &.entering,
  &.leaving {
    transform: translate(-50%, 50%);
    opacity: 0;
  }

  &__message {
    @include ellipsis;
  }
}
