@import '../../core/colors';
@import '../../core/variables';

.question-options {
  margin-top: 0.5rem;
}

.question-option {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: $medium-grey;

  &.mcq {
    display: block;
  }

  &.selected {
    color: $blue;
  }

  &.incorrect {
    color: $pink;
  }

  &__label {
    @include center;
    margin-right: $spacing;
    font-weight: $font-thick;
    border-radius: 50%;
    width: 1.3125rem;
    height: 1.3125rem;
    color: $medium-grey;
    background-color: $lightest-grey;
    text-transform: uppercase;
  }

  &.selected &__label {
    color: $white;
    background-color: $blue;
  }

  &.incorrect &__label {
    color: $white;
    background-color: $pink;
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: $font-thick;
    color: $medium-grey;
  }

  &.selected &__title {
    color: $blue;
  }

  &.incorrect &__title {
    color: $pink;
  }

  &__details {
    font-size: $font-sm;
    margin-left: 1.75rem;
    margin-top: 0.5rem;

    &.no-indent {
      margin-left: 0;
    }
  }
}
