@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.suggested-assignment-widget {
  background-color: $white;
  padding: $spacing;
  cursor: pointer;
  color: $light-grey;
  border-radius: $border-radius-std;
  overflow: hidden;
  width: 100%;
  @include children-margin(0.5rem, 'bottom', 'div');

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $light-black;
    font-weight: $font-thick;
  }

  .tag {
    background-color: $grey;
  }

  &.used .tag {
    background-color: $green;
  }

  &__title,
  &__published-on {
    @include ellipsis;
    color: $medium-dark-grey;
  }
}
