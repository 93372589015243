@import '../../../core/colors';
@import '../../../core/mixins';
@import '../../../core/variables';

.create-course-screen {
  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375rem;
  }
}

.copy-course {
  &__radio-button {
    margin-right: 0.75rem;
    width: 1rem;
    height: 1rem;
  }

  &__option {
    display: flex;
    cursor: pointer;
    padding: 0.75rem;
    margin: 0.75rem 0;
    background-color: $white;
    border-radius: $border-radius-std;

    & &-title {
      font-size: $font-md;
      line-height: 1;
    }
  }

  &__separator {
    color: $grey;
    font-size: $font-lg;
    text-align: center;
    width: 100%;
  }
}

.blueprint-course {
  flex: 1;
  color: $grey;

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__code {
    text-transform: uppercase;
    font-weight: $font-thick;
    color: $blue;
  }

  &.is-admin &__code {
    color: $green;
  }

  &__title {
    @include ellipsis;
    font-weight: 400;
    font-size: $font-md;
    max-width: 100%;
    margin-bottom: 0.5rem;
    color: $black;
  }

  &__admin {
    padding: 0.875rem 0;
  }

  &__role {
    text-transform: capitalize;
  }

  &__info-section {
    font-size: $font-sm;
    margin-bottom: 1rem;
  }
}

.bpc-info {
  margin: 0.75rem 0;

  &__link {
    padding: 0.5rem;
    display: flex;
    text-decoration: none;
    color: $blue;
    border-bottom: 1px solid $lightest-grey;

    &:last-child {
      border-bottom: none;
    }
  }

  &__book {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid $lightest-grey;

    &__details {
      flex: 1;
    }

    &__author {
      margin-top: 0.5em;
      color: $light-grey;
      text-decoration: italic;
    }

    &__isbn {
      margin-top: 0.4375rem;
      color: $light-grey;
    }

    &__remarks {
      align-items: flex-end;
      display: flex;
    }
  }

  &__topic {
    background-color: $white;
    padding: 0.75rem 0;
    border-bottom: 1px solid $lightest-grey;
    font-size: $font-md;

    &.sub {
      font-size: $font-sm;
      padding: 0.75rem 1rem;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__files {
    padding: 0 0.5rem;
  }
}
