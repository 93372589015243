@import '../../core/mixins';
@import '../../core/variables';

$comment-action-separator: 1px solid lightgrey;
$comment-action-bg: rgb(246, 246, 246);

.comment {
  display: flex;
  flex-direction: column;
  padding: 0.75em;
  background-color: $white;
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &.has-user-info {
    border-top: 1px solid $lightest-grey;
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375em;
  }

  &__actions {
    @include paper;

    position: absolute;
    cursor: pointer;
    right: 0.4375rem;
    top: 0.4375rem;
    border-radius: 0.2625rem;
    display: none;
    box-shadow: $default-shadow;

    & > span,
    & > i {
      border-right: $comment-action-separator;

      &:last-child {
        border-right: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.active {
    background-color: rgb(246, 246, 246);
  }

  &.active &__actions {
    display: flex;
    background-color: $comment-action-bg;
  }

  &__delete-button {
    @include center;
    font-size: 1.1375rem;

    &__icon {
      color: $red;
      padding: 0.4rem 0;
      margin: 0 0.5rem;
    }
  }

  &__tag {
    margin-left: 0.8rem;
    height: 80%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: $dark-pink;
    text-align: center;
    font-weight: 900;
    border-radius: $border-radius-std;

    &__text {
      color: $white;
      font-size: $font-xsm;
    }
  }

  &__creator {
    display: flex;

    &__name {
      font-weight: $font-thick;
      height: 1.3125rem;
    }
  }

  &__date {
    font-style: italic;
    color: $another-grey;
  }

  &__award {
    @include center;
    padding: 0.25rem;
    width: 1.875rem;
    height: 1.875rem;

    &__action {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__user-info {
    display: flex;
    margin-bottom: 0.525rem;

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  &__image-thumb {
    margin-top: 0.3rem;
    margin-right: auto;
    object-fit: contain;
    width: initial;
    cursor: pointer;
    display: block;
    margin-bottom: 0.4375em;
    max-width: 100%;
  }
}
