@import '../../core/variables';

.course {
  width: 100%;
  height: 100%;
  position: relative;
  transition: height 0.3s ease-in-out;

  &__loader {
    z-index: $z-course-loader;
  }

  &__wrapper {
    height: 100%;
    overflow-y: hidden;
  }

  &__floating-bar {
    @include main-panel;
    position: absolute;
    color: $white;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba($color: #000, $alpha: 0.98);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: $z-course-floating-bar;

    &__action {
      color: $yellow;
      padding: 0.5rem 1rem;
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__end-dialog {
    background-color: $lightest-grey;
  }

  &__end-heading {
    padding: 0.5rem 1rem;
  }

  &__sub-heading {
    color: $grey;
    padding: 0.5rem 1rem;
  }

  &__bullet {
    display: flex;
    padding: 0.25rem 1rem;
    &::before {
      content: '-';
      display: inline;
      margin-right: 0.5rem;
    }
  }

  &__action {
    background-color: $white;
    width: 100%;
    padding: 0.5rem 2rem;
    color: $blue;
    cursor: pointer;
    margin: 0.4375rem 0;
  }

  &__cell {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__footer-note {
    font-size: $font-sm;
    color: $light-grey;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;

    i {
      margin-right: 0.5rem;
    }
  }

  &__timezone-row {
    display: flex;
    background-color: $white;
    padding: 0.5rem;
    width: $main-panel-width;
    max-width: 100%;
    cursor: pointer;
    align-items: center;
  }

  &__timezone-title {
    font-size: $font-md;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  &__timezone-subtitle {
    color: $light-grey;
    font-size: $font-xsm;
  }
}
