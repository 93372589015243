@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

$right-offset: 2.625rem;

.floating-menu {
  &:not(.open) &__overlay {
    display: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: initial;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.open &__container {
    max-height: 31.25rem;
  }

  &__container {
    position: fixed;
    right: $context-panel-width + $right-offset;
    bottom: 7.4375rem;
    box-shadow: #333 1px 1px 5px 0px;
    background-color: $white;
    transition: max-height 0.5s linear;
    overflow-y: hidden;
    max-height: 0;
    z-index: $z-floating-menu;

    @include narrow {
      right: $right-offset;
    }

    @include mobile {
      bottom: 5.6875rem;
      right: 0.875rem;
    }
  }

  &__item {
    padding: 0.875rem;
    justify-content: left;
    cursor: pointer;
    font-weight: $font-bold;

    &.title {
      justify-content: center;
      color: $grey;
    }
  }
}
