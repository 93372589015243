@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.resource {
  &__delete-button {
    color: $red;
    padding: 0.5rem;
    cursor: pointer;
    margin-right: -0.5rem;
    font-size: $font-md;
  }

  &__types {
    display: flex;
    justify-content: space-evenly;
    background-color: $white;
  }

  &__type-button {
    @include center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    height: 100%;
    color: $light-grey;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}
