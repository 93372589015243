.stepper {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: $font-thick;

  &--disabled {
    opacity: 0.5;
  }

  &__button {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem;
    border-radius: 3px;
    border: none;
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
    }

    &--inc {
      background-color: $blue;
      color: $white;
    }

    &--dec {
      background-color: #dadada;
      color: $medium-grey;
      padding-bottom: 1px;
    }
  }

  &__value {
    color: $blue;
    margin: 0 0.5rem;
    min-width: 20px;
    text-align: center;
  }
}
