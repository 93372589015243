//===========
// font-sizes
//===========
$font-sizes: (
  'xxsm': 0.5rem,
  'xsm': 0.75rem,
  'sm': 0.875rem,
  'md': 1rem,
  'lg': 1.125rem,
  'xlg': 1.25rem,
  'xxlg': 1.75rem,
);
$font-xxsm: map-get($font-sizes, 'xxsm');
$font-xsm: map-get($font-sizes, 'xsm');
$font-sm: map-get($font-sizes, 'sm');
$font-md: map-get($font-sizes, 'md');
$font-lg: map-get($font-sizes, 'lg');
$font-xlg: map-get($font-sizes, 'xlg');
$font-xxlg: map-get($font-sizes, 'xxlg');

//=============
// font-weights
//=============
$font-weights: (
  'bold': bold,
  'thick': 700,
  'thin': 400,
);
$font-bold: map-get($font-weights, 'bold');
$font-thick: map-get($font-weights, 'thick');
$font-thin: map-get($font-weights, 'thin');

//===============
// shadow effects
//===============
$default-shadow: #dadada 0px 1px 10px 0px;
$spread-shadow: #dadada 0px 1px 10px 6px;
$dark-shadow: #666 1px 1px 5px 0px;

//=====================
// transition functions
//=====================
$default-transition-fn: cubic-bezier(0.23, -0.3, 0.24, 1.2);

//==============
// border-radius
//==============
$border-radius-std: 3px;

//==========
// z-indices
//==========
$z-fab: 1;
$z-faq-dialog: 1;
$z-nav-toggle: 1;
$z-course-widget: 1;
$z-notification: 1;
$z-timeline-widget: 1;
$z-image-preview: 1;
$z-header-button: 2;
$z-faq-dialog-body: 2;
$z-tabs: 3; // old value was: 2
$z-header: 3;
$z-action-bar: 3; // old value was: 2
$z-course-loader: 95;
$z-tabs-mobile: 100;
$z-tooltip: 100;
$z-floating-menu: 300;
$z-add-question: 500;
$z-analytic-panel: 1000;
$z-course-floating-bar: 1000;
$z-create-assignment-screen: 1000;
$z-drawer: 1001;
$z-app-overlay: 1011;
$z-contex-panel: 1011;
$z-sidebar: 1102;
$z-quick-tips: 1103;
$z-vcall-frame: 1103;
$z-alert-overlay: 4000;
$z-loader: 10000;
$z-server-error: 100000;
$z-toast: 100001;
$z-snakebar: 1000000;

//=====================
// component dimensions
//=====================
$spacing: 0.875rem;

$context-panel-width: 21.875rem;
$sidebar-width: 6.25rem;
$main-panel-width: 30.625rem;

$action-bar-height: 3.5rem;

$header-height: 3.5rem;
$header-height-mobile: 4rem;

$tab-height: 3.0625rem;
$tab-height-mobile: 4.375rem; // with icons only

$home-content-width: 26.25rem;

$timeline-widget-height: 6.25rem;
$timeline-widget-icon: 3.75rem;
