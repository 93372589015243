.acadly-core-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.acadly-core-flex-column {
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}

.acadly-core-justify-center {
  justify-content: center;
  -ms-flex-pack: center;
  -moz-box-pack: center;
  -webkit-box-pack: center;
}

.acadly-core-align-center {
  align-items: center;
  -ms-flex-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
}

.acadly-core-space-between {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.acadly-core-space-around {
  -webkit-justify-content: space-around;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.acadly-core-flex-end {
  -ms-flex-pack: end;
  -moz-box-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
