@import '../../core/variables';

.faq-screen {
  position: relative;
  background-color: $white;
  height: 100%;
  overflow-y: hidden;

  &__content {
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &__section {
    padding: 0;
    margin: 0.875rem 0;
    list-style: none;
  }

  &__title {
    font-weight: $font-bold;
  }

  &__question {
    color: $blue;
    margin-top: 0.4375rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__dialog {
    width: 100%;

    &__overlay {
      position: absolute;
      display: none;
      z-index: $z-faq-dialog;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color: #000, $alpha: 0.3);
    }

    &.open &__overlay {
      display: unset;
    }

    &__body {
      background-color: $white;
      position: absolute;
      top: 100%;
      padding: 0.4375rem 0.875rem;
      border-radius: $border-radius-std;
      z-index: $z-faq-dialog-body;
      overflow-y: hidden;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      max-width: 85%;
      height: 35rem;
      max-height: 90%;
      transition: top 0.5s $default-transition-fn;
    }

    &.open &__body {
      top: 2.625rem;
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__header {
      color: $light-grey;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__close {
      cursor: pointer;
    }

    &__header-padding {
      margin-bottom: 0.4375rem;
      visibility: hidden;
    }

    &__scroller {
      height: 100%;
      overflow: auto;
    }

    &__question {
      height: 100%;
      overflow: hidden;
    }

    &__question-title {
      font-weight: $font-bold;
      color: $blue;
      margin-bottom: 0.4375rem;
    }

    &__next-question {
      color: $blue;
      cursor: pointer;
      margin-left: auto;
    }
  }
}
