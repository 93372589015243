@import '../../core/variables';

.class-analytics {
  &__avatar {
    width: 2.625em;
    height: 2.625em;
    margin-right: 0.4375em;
  }

  &__total-award {
    display: flex;
    align-items: center;
  }

  &__awards {
    padding: 0.15rem 0.375rem;
    margin: 0 0.25rem 0 -0.5rem;
    font-size: 0.8rem;
    font-weight: $font-bold;
  }

  &__participation {
    // work around for hidden content on scrolling student list
    padding-bottom: 3rem;
  }
}
