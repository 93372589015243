@import '../../core/mixins';

.resource-analytics {
  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
  }

  &__total-clicks {
    @include paper;
    margin-bottom: 0.4375rem;
    padding: 0.4375rem 0.875rem;
    display: flex;
  }
}
