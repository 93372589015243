@import '../../core/mixins';

.settings-screen {
  &__paper {
    @include paper;
    width: 100%;
    max-width: 96vw;
    margin-bottom: 0.4375rem;
    flex-shrink: 0;

    &.first {
      margin-top: 0.4375rem;
    }
  }

  &__avatar {
    width: 1.75rem;
    height: 1.75rem;

    &--md {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0.5em;
      width: 2.625rem;
      height: 2.625rem;
    }

    &--lg {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &__zoomus-icon {
    width: 2rem;
  }
}

.zoom-settings {
  background-color: $white;
  width: 100%;
  min-height: 100px;
  padding: 1rem;

  a {
    display: inline-block;
  }

  &__logo {
    display: block;
    width: 110px;
    margin: 1rem auto;
  }

  &__header {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 1rem;

    &--title {
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  &__list-item {
    margin: 1rem;
    display: flex;

    .bullet {
      content: '-';
      padding: 0 0.5rem;
    }
  }

  &__add-button {
    width: 200px;
    display: block;
    cursor: pointer;
    margin: 1.5rem auto;
  }
}

.pro-settings {
  width: 100%;

  @include mobile {
    padding: 0 1rem;
  }

  &__purchase {
    color: $white;
    background-color: $blue;
    padding: 1rem;
    border-radius: 4px;
    margin: 0.5rem 0 1.5rem;
    font-size: $font-md;
    font-weight: $font-thick;
    text-transform: uppercase;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 4px 0 rgba(0, 0, 0, 0.14);
  }

  &__referral {
    display: flex;
    align-items: flex-start;

    &-code {
      text-transform: uppercase;
      color: $green;
      font-weight: $font-thick;
      text-align: right;
    }

    &-validity {
      margin-top: 0.5rem;
      color: $grey;
      font-size: $font-xsm;
    }
  }
}
