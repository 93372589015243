@import '../../core/colors';
@import '../../core/variables';

.raised-button {
  background: $white;
  box-shadow: $default-shadow;
  padding: 0.75rem 0.875rem;
  text-align: center;
  cursor: pointer;

  &--sm {
    padding: 0.5rem;
  }

  &.disabled {
    cursor: default;
    color: $light-grey;
  }
}
