@import '../../core/colors';
@import '../../core/variables';

.new-feature {
  margin: 1rem;
  padding: 1rem;
  background-color: $white;

  &__release-date {
    font-size: $font-sm;
    color: $light-grey;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-weight: $font-bold;
    font-size: $font-md;
    margin-bottom: 1rem;
    color: $blue;
  }

  &.seen &__title {
    color: $grey;
  }

  &__view-more {
    margin-top: 0.5rem;
    color: $blue;
    cursor: pointer;
    display: inline-block;
  }

  &.seen &__view-more {
    color: $grey;
  }
}
