@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.quiz {
  &__manual-stop {
    padding: 0.5rem 0.875rem;
    background-color: $red;
    color: $white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      margin: 0.5rem 0;
    }

    &__note {
      font-size: $font-xsm;
      color: $light-grey;
      padding-top: 0.5rem;
    }

    &__button {
      margin-left: auto;
      padding: 0.25rem 0.5rem;
      transform: translateX(0.5rem);
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;

    &__action {
      color: $blue;
      cursor: pointer;
      margin: 2 * $spacing 0 $spacing 2 * $spacing;
    }
  }

  &__fab-icon {
    @include fab-icon-plus;
  }

  &__raised-button {
    margin-top: 0.875rem;
    color: $blue;
    &.danger {
      color: $red;
    }
  }

  &__add-question {
    background-color: $lightest-grey;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-add-question;
    width: 100%;

    &__heading {
      background-color: $white;
      box-shadow: $default-shadow;
      display: flex;
      justify-content: center;
      padding: $spacing;
      font-size: $font-lg;
      color: $light-black;
      margin-bottom: 0.4375rem;
    }

    &__title {
      padding: 0.5rem;
      margin-top: $spacing;
      color: $light-grey;
      font-size: $font-xsm;
      text-transform: uppercase;
    }

    &__details {
      background-color: $white;
      padding-bottom: 0.4375rem;
      margin-top: 1rem;

      @include mobile {
        margin-top: 0;
        padding-bottom: 0;
      }
    }

    &__options {
      margin: $spacing 0;
      color: $grey;
    }

    &__option {
      padding: 0.75rem 1rem;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      background-color: $white;
      margin-bottom: 1px;

      &.selected {
        color: $blue;
      }
    }

    &__radio-button {
      margin-right: 0.5rem;
    }

    &__answer {
      display: flex;
      justify-content: space-between;
      padding: $spacing 0.5rem;
    }

    &__option-label {
      @include center;
      border-radius: 100%;
      width: 1.75rem;
      height: 1.75rem;
      line-height: 1;

      &.selected {
        color: $white;
        background-color: $blue;
      }
    }
  }

  &__progress {
    background-color: transparent;
    border-radius: 0;
    height: 1rem;
    margin-left: 0.875rem;
    flex: 1;

    &.danger .progress-bar {
      background-color: $orange;
    }
  }
}
