$content-width: 490px;

.join-course-payment {
  font-size: $font-sm;
  min-height: 100px;
  display: flex;
  align-items: center;

  &__body {
    width: $content-width;
    max-width: 100%;
    padding: 0.5rem;
    margin: auto;
    background-color: $white;
  }

  &__description {
    padding: 0.5rem 1rem;
  }

  &__section-title {
    color: #333;
    font-weight: $font-thick;
    font-size: $font-xsm;
    padding: 0.5rem 1rem;
  }

  &__cell {
    display: flex;
    padding: 0.5rem 1rem;
  }

  &__cell-image {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 1rem;
    color: $blue;
  }

  &__cell-body {
    flex: 1;
  }

  &__cell-title {
    color: $blue;
    margin-bottom: 0.25rem;
    font-weight: $font-thick;
  }

  &__cell-details {
    font-weight: $font-thin;
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    width: 320px;
    height: 48px;
    max-width: 100%;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    font-weight: $font-thick;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 4px 0 rgba(0, 0, 0, 0.14);

    &.red {
      background-color: $red;
    }

    &.blue {
      background-color: $blue;
    }

    &.green {
      background-color: $green;
    }

    &.grey {
      color: #333;
      background-color: $lightest-grey;
    }

    &[disabled] {
      color: $medium-grey;
      background-color: #dadada;
      box-shadow: none;
      cursor: default;
    }

    @include mobile {
      font-size: $font-xsm;
      width: 100%;
    }
  }

  &__form-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-weight: $font-thick;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    &.blue {
      color: $blue;
    }

    &.light-grey {
      color: $light-grey;
    }

    &.orange {
      color: $orange;
    }

    &.indent {
      padding-left: 2rem;
    }
  }

  &__v-center {
    display: flex;
    align-items: center;
  }

  &__dropdown {
    font-size: inherit;

    &-wrapper {
      width: 50%;
    }

    &-options {
      font-size: inherit;
    }
  }

  &__form-radio {
    margin-right: 0.5rem;
    flex-shrink: 0;
    cursor: pointer;
    color: inherit;
  }

  &__referral-input {
    border: none;
    border-bottom: 2px solid $grey;
    background-color: $white;
    outline: none !important;
    padding: 0.5rem 0.75rem;
    width: 196px;
    max-width: 100%;
    font-size: inherit;

    &:focus {
      background-color: $lightest-grey;
      border-color: $blue;
    }

    &.has-error {
      border-color: $red;
    }
  }

  &__referral-error {
    color: $red;
    margin-left: 3.875rem;
    margin-bottom: 0.5rem;
    margin-top: -0.625rem;
    font-size: $font-xsm;
    font-weight: $font-thick;
  }

  & &__referral-action {
    margin-right: -0.875rem;

    &:hover {
      background-color: $white;
    }
  }

  &__checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $blue;
    position: relative;
    cursor: pointer;
    margin-right: 0.5rem;

    &.checked::after {
      content: 'L';
      display: inline-block;
      transform: scaleX(-1) rotate(-35deg);
      font-weight: $font-thick;
      color: $green;
      position: absolute;
      left: 6px;
      top: -1px;
    }
  }

  &__terms {
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  &__terms-error {
    color: $red;
    margin-left: 2.75rem;
    margin-bottom: 0.5rem;
    font-weight: $font-thick;
    margin-top: -10px;
  }

  &__card-input {
    margin: 0.5rem 1rem;
    border: 1px solid $blue;
    border-radius: 3px;
    padding: 0.75rem;
  }

  &__check {
    font-size: 60px;
    text-align: center;
    margin: 1rem;
    color: $green;
    font-weight: $font-thick;
    &.failed {
      color: $red;
    }
  }

  &__referral-code {
    font-size: 2rem;
    line-height: 2.5rem;
    color: $blue;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
  }

  &__referral-details {
    color: #333;
    font-size: $font-sm;
    line-height: 1.25rem;
    font-weight: $font-thick;
    background-color: rgba($color: $green, $alpha: 0.2);
    margin: 0.5rem 1rem;
    padding: 0.5rem 1rem;
  }

  &__referral-note {
    color: #d95960;
    margin-top: 2px;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    font-size: $font-xsm;
    font-weight: $font-thin;
    line-height: 1rem;
  }

  &__payment-error {
    background-color: rgba($color: $red, $alpha: 0.2);
    padding: 1rem;
    border-radius: 4px;
  }

  &__payment-success {
    background-color: rgba($color: $green, $alpha: 0.2);
    padding: 1rem;
    border-radius: 4px;
  }

  &__payment-fieldset {
    border-radius: 4px;
    border: none;
    font-size: 15px;
    line-height: 1.4em;

    & > label {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 42px;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
      color: #8898aa;
      font-weight: 400;
      border-bottom: 1px solid #f0f5fa;

      & > span {
        min-width: 125px;
        padding: 0 15px;
        text-align: right;

        @include mobile {
          width: 20%;
          min-width: unset;
        }
      }
    }

    input,
    .card-input,
    .select-country {
      flex: 1;
      padding: 0 15px;
      background: transparent;
      font-weight: 400;
      color: #31325f;
      outline: none;
      cursor: text;
      border-style: none;
      font-size: inherit;

      &::placeholder {
        color: #aab7c4;
      }
    }

    .select-country {
      display: flex;
      align-items: center;

      span {
        margin-right: 0.5rem;
      }
    }

    select {
      flex: 1;
      border-style: none;
      outline: none;
      appearance: none;
      outline: none;
      color: #313b3f;
      cursor: pointer;
      background: transparent;
      margin: 0 -15px 0 -30px;
      padding: 0 15px 0 30px;
      width: 100%;
    }
  }

  &__inr-note {
    color: $orange;
    margin-left: 125px;
    padding: 0 1rem;

    @include mobile {
      margin-left: 20%;
    }
  }
}
