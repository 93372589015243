@import './colors';
@import './mixins';
@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

p {
  margin: $spacing 0;
}

a {
  color: $blue;
  text-decoration: none;
  word-break: break-all;

  &.no-break {
    white-space: nowrap;
  }
}

//===============
// helper classes
//===============

// applied on <body> when dragging to disable
// scrolling effect on touch devices
.disable-scroll {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

.auto-margin {
  margin: auto;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.icon-24px {
  width: 24px;
  height: 24px;
}

// font-weights
@each $selector, $font-weight in $font-weights {
  .fw-#{$selector} {
    font-weight: $font-weight;
  }
}

// font-colors
@each $selector, $color in $colors {
  .fc-#{$selector} {
    color: $color;
  }
}

// font-sizes
@each $selector, $size in $font-sizes {
  .fs-#{$selector} {
    font-size: $size;
  }
}

// background-colors
@each $selector, $color in $colors {
  .bg-#{$selector} {
    background-color: $color;
  }
}

.ripple {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

.badge {
  display: inline-block;
  border-radius: 10rem;
  padding: 0.25rem 0.5rem;
  font-size: $font-xsm;
  background-color: $orange;
}

.tag {
  line-height: 1;
  padding: 2px 4px;
  margin: 4px;
  color: #fff;
  border-radius: 4px;
  display: inline;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;

  @include background-modifier('red');
  @include background-modifier('blue');
  @include background-modifier('green');
  @include background-modifier('orange');
}

.tooltip {
  position: relative;

  &::before {
    content: attr(data-tooltip);
    display: inline-block;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba($color: $black, $alpha: 0.75);
    color: $white;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: 0.2s;
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.25;
  }

  &:hover::before {
    opacity: 1;
    bottom: 100%;
  }

  &_left::before {
    left: unset;
    transform: unset;
    right: 0;
  }
}

// list separator: used in timeline and comments for showing NOW
.separator {
  color: $orange;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $white;
  padding: 0.4375rem 0;
  position: relative;

  &__line {
    height: 1px;
    background-color: $orange;
    &.small {
      width: 1.75rem;
    }
    &.mini {
      width: 0.875rem;
    }
    &.fluid {
      flex: 1;
    }
  }

  &__text {
    font-size: $font-xsm;
    font-weight: $font-thick;
    margin: 0 0.4375rem;
  }
}

.panel {
  background: $white;
  margin: $spacing 0;
  padding-bottom: $spacing;

  &--inline {
    padding: 0;
  }

  &__header {
    padding: 0.5rem $spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #eee;
    font-size: $font-lg;
    color: $grey;
    min-height: 2.75rem;

    i,
    .icon {
      color: $light-grey;
      cursor: pointer;
      align-self: center;
      padding: 0.25rem 0.5rem;
      margin-right: -0.5rem;

      &:hover {
        color: $blue;
      }
    }
  }

  &--inline &__header {
    border-bottom: none;
  }

  &__content {
    padding: 0.5rem 1rem;
  }
}

.user {
  display: flex;
  padding: $spacing;

  &__checkbox {
    align-self: center;
    margin-right: 0.5rem;
  }

  & &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.5rem;
  }

  &--small &__avatar {
    width: 2.25rem;
    height: 2.25rem;
  }

  &__details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 0.875rem;
    overflow: hidden;
  }

  &--small &__details {
    font-size: 0.75rem;
  }

  &__title {
    @include ellipsis;
  }

  &__action {
    margin-left: auto;
    align-self: center;
  }
}

.cell {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--card {
    background-color: $white;
    margin: $spacing 0;
  }

  &--full-width {
    padding: 0.5rem 0;
  }

  &__label {
    font-weight: $font-thick;
    color: $medium-grey;
  }

  &__value {
    font-weight: $font-thin;
    color: $medium-grey;
  }
}

@keyframes highlight-submit {
  0% {
    color: $red;
    background-color: $white;
  }
  50% {
    color: $white;
    background-color: $red;
  }
  100% {
    color: $red;
    background-color: $white;
  }
}

.highlight-submit {
  animation: highlight-submit 2s ease-in-out 0s infinite;
}

.activity {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.with-action-bar {
    padding-top: $action-bar-height;
  }

  .cell {
    padding: 0.5rem 0;
  }

  .user {
    padding: $spacing 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__content {
    margin: 2 * $spacing 0;

    @include mobile {
      padding: 0 $spacing;
    }
  }

  &__section {
    margin: 2 * $spacing 0 $spacing;
  }

  &__title {
    margin: 2 * $spacing 0 $spacing;
    font-size: $font-lg;
    word-break: break-word;
  }

  &__description {
    margin: $spacing 0;
  }

  &__subtitle {
    color: $medium-grey;
    font-size: $font-sm;
    font-weight: $font-thick;
    margin: 2 * $spacing 0 $spacing;
  }

  &__attachments {
    display: flex;
    flex-direction: column;
    margin: $spacing 0;
  }

  &__note {
    display: flex;
    align-items: center;
    color: $orange;
    padding: $spacing 0;
    font-size: $font-sm;

    &.with-padding {
      padding: $spacing;
    }

    & > i {
      margin-right: $spacing;
    }
  }
}

.analytics {
  width: 100%;
  height: 100%;

  &__body {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  &__header {
    text-align: center;
    font-size: $font-lg;
    margin: 1rem 0 0.75rem;
  }

  &__sub-header {
    text-align: center;
    font-size: $font-sm;
    color: $light-grey;
    margin: 0.5rem 0;
  }

  &__sub-cell {
    padding: 0.25rem 0;
    font-size: $font-sm;
  }

  &__section {
    margin-top: 0.875rem;
  }

  &__tab-content {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    padding: $tab-height 0;
  }

  &__content {
    padding-bottom: 2rem;
    @include main-panel;
    @include mobile {
      padding: 0 $spacing;
    }
  }

  &__export {
    display: flex;
    align-items: center;
    padding: 1rem;
    text-align: left;
    margin: 1rem 0;
    @include children-margin(1rem);

    &__icon {
      color: $blue;
      font-size: $font-xlg;
    }

    &__label {
      color: $blue;
      font-size: $font-lg;
    }
  }

  &__button {
    @include center;
    @include children-margin(0.5rem);
  }

  &__user {
    padding: 0;
    margin: 1rem 0;

    .user__avatar {
      height: 3rem;
      width: 3rem;
    }

    .user__details {
      width: 100%;
      color: $light-grey;
    }

    .user__title {
      color: $green;
      display: flex;
      justify-content: space-between;
    }
  }
}

.student {
  background-color: $white;
  padding: $spacing;
  border-bottom: 1px solid $lightest-grey;

  &--no-bg {
    background-color: transparent;
  }

  &.pointer {
    @include button-hover-modifier;
  }
}
