@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.assignment-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__action-bar {
    @include main-panel;
    top: 0;
    position: sticky;
    z-index: $z-action-bar;
    display: flex;
    justify-content: space-around;
    background-color: $white;
    height: $action-bar-height;
    border-top: 1px solid $lightest-grey;
    box-shadow: $default-shadow;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    max-width: $main-panel-width;

    @include mobile {
      padding: 0 1rem 1rem;
    }
  }

  &__info {
    margin-top: 0.75rem;
  }

  &__extension-icon {
    padding: 0.5rem 1rem;
    font-size: $font-xsm;
    color: $blue;
    font-weight: $font-bold;
    text-transform: uppercase;
  }
}
