.activity-prefs {
  &__title {
    font-size: $font-xsm;
    color: $grey;
    padding: 0.5rem;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__helper-text {
    padding: 0.5rem 1rem;
    color: $grey;
    font-size: $font-xsm;
  }

  &__editable-cell {
    padding: 0.5rem 0.875rem;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    cursor: pointer;
    margin-bottom: 1px;
  }

  &__editable-cell-value {
    color: $grey;
    display: flex;
    align-items: center;
  }

  &__duration-cell {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
  }

  &__warning {
    display: flex;
    color: $orange;
    padding: 0.5rem 1rem;
    font-size: $font-xsm;

    &.full-width {
      padding: 0.5rem 0rem;
    }

    i {
      margin-right: 0.5rem;
      padding: 4px 0;
    }
  }
}
