@import '../../core/colors';
@import '../../core/variables';

.announcement-widget {
  border-bottom: 2px solid transparent;
  background-color: $white;
  width: 100%;
  padding: 0.4375rem 0.875rem;
  display: flex;
  height: $timeline-widget-height;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid $blue;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 98px);
  }

  &__heading {
    font-weight: $font-bold;
    margin-bottom: 0.4375rem;
  }

  &__body {
    font-weight: $font-thin;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 2.5rem;
    line-height: 1.25rem;
    display: flex;
  }

  &__avatar {
    width: 2rem;
    height: 2rem;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 0.4375em;
  }

  &__title {
    margin-top: auto;
    margin-bottom: auto;
  }
}
