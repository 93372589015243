@import '../../core/colors';
@import '../../core/variables';

$width: 420px;
$transition-time: 0.5s;
$top: $header-height + $tab-height + 1rem;

.quick-start-tips {
  position: fixed;
  left: $sidebar-width + 1rem;
  top: $top;
  width: $width;
  padding: 1rem;
  max-height: calc(100vh - #{$top + 1rem});
  border-radius: 16px;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(209, 18, 80, 0.5);
  background-color: $white;
  z-index: $z-quick-tips;
  cursor: move;
  transition: width $transition-time ease, height $transition-time ease;
  transform-origin: top left;
  overflow: auto;
  animation-name: qst-in;
  animation-duration: $transition-time;
  animation-timing-function: ease;

  @keyframes qst-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.collapsed {
    cursor: pointer;
    width: 175px;
    height: 60px;
    overflow: hidden;
    transition: all $transition-time ease;
  }

  &:not(.collapsed) {
    /**
         * hack to hide scrolls during collapse/expand animation
         * @see https://css-tricks.com/hide-scrollbars-during-an-animation/
         */
    animation-name: qst-in, hide-scroll;
    animation-duration: $transition-time, $transition-time;

    @keyframes hide-scroll {
      from,
      to {
        overflow: hidden;
      }
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;

    &.sm {
      width: 1rem;
      height: 1rem;
    }
  }

  @keyframes beat {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.25);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.flash &__bolt-icon {
    animation: beat 1s ease infinite;
  }

  &__header {
    font-size: $font-md;
    font-weight: $font-bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &__close {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transform: scale(1);
    transition: transform $transition-time ease, width $transition-time ease;
  }

  &.collapsed &__close {
    width: 0;
    transform: scale(0);
  }

  &__body {
    opacity: 1;
    width: calc(#{$width} - 2rem - 4px);
    transform-origin: top left;
    transition: transform $transition-time ease, opacity $transition-time ease;
  }

  &.collapsed &__body {
    opacity: 0;
    transform: scale(0);
  }

  &__section-heading {
    margin: 1rem 0 0.5rem;
    font-size: $font-sm;
    font-weight: $font-bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &__section-content {
    font-size: $font-sm;
    line-height: 1.33;
    margin: 0;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
