@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.suggested-activity-widget {
  background-color: $white;
  padding: $spacing;
  display: flex;
  cursor: pointer;
  color: $light-grey;
  margin-bottom: 1px;
  border-radius: $border-radius-std;

  &__icon {
    @include center;
    width: 3rem;
    font-size: $font-xxlg;
    margin-right: $spacing;
    color: $blue;
    flex-shrink: 0;
  }

  &.used &__icon {
    color: $green;
  }

  &.hidden &__icon {
    color: $light-grey;
  }

  &__details {
    flex: 1;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $medium-dark-grey;
    font-weight: $font-thick;
    margin-bottom: 0.5rem;
  }

  .tag {
    background-color: $grey;
  }

  &.used .tag {
    background-color: $green;
  }

  &.suggested .tag {
    background-color: $blue;
  }

  &__rhs {
    font-weight: $font-thin;
  }

  &__title {
    @include ellipsis;
    color: $medium-dark-grey;
  }
}
