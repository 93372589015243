.poll-analytics {
  &__avatar {
    height: 2.625rem;
    width: 2.625rem;
  }
  &__response {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
