@import '../../core/mixins';
@import '../../core/variables';

.app {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    display: flex;
    height: 100%;
    flex-direction: row;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: $z-app-overlay;
  }

  & &__sidebar-wrapper {
    border-right: unset;
    background-color: $dark-blue;
    z-index: $z-sidebar;
    width: $sidebar-width;
  }

  &__main {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: $sidebar-width;
    transition: all 0.2s ease-in-out;
    width: calc(100% - #{$sidebar-width} - #{$context-panel-width});

    @include narrow {
      margin-left: 0;
      width: 100%;
    }

    .main-wrapper {
      height: 100%;
      max-height: calc(100% - #{$header-height});
      @include mobile {
        max-height: calc(100% - #{$header-height-mobile});
        z-index: $z-header;
      }
    }
  }

  & &__context-panel-wrapper {
    z-index: $z-contex-panel;
    width: $context-panel-width;
    max-width: 100vw;
    border-left: 1px solid $layout-border-grey;

    @media (min-width: #{$context-panel-width}) {
      &.expanded {
        width: $context-panel-width * 3;
      }
    }

    @include narrow {
      border-left: none;
    }

    @include mobile {
      width: 100vw;
    }
  }

  &__analytics {
    position: absolute;
    z-index: $z-analytic-panel;
    top: 0;
    overflow-y: hidden;
    height: 0%;
    width: 100%;
    transition: height 0.2s ease-in;
    background-color: $lightest-grey;

    &.open {
      height: 100%;
    }
  }

  &__notification {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    background-color: $white;
    box-shadow: $light-grey 1px 1px 5px 0px;

    &-avatar {
      width: 2rem;
      height: 2rem;
      margin-bottom: auto;
      margin-right: 0.5rem;
    }
  }
}

.promo {
  &__fab {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0.5rem;
    background-color: $blue;
    font-size: 0.875rem;
    margin: 0 0.5rem;
    color: $white;
    border-radius: 50%;
  }

  &__action.green {
    margin-top: 2rem;
  }
}
