@import '../../core/mixins';

.class-agenda {
  padding-bottom: 5.25rem;
  padding-top: $tab-height;

  @include mobile {
    padding: $tab-height $spacing 5.25rem;
  }
}
