@import '../../core/colors';
@import '../../core/variables';

.suggested-poll {
  &__option {
    border-radius: $border-radius-std;
    background-color: $white;
    margin-bottom: 1px;
    padding: $spacing;
  }

  &__option-header {
    font-weight: $font-thick;
    color: $medium-grey;
    margin-bottom: 0.5rem;
  }
}
