@import '../../core/colors';
@import '../../core/variables';

.notification {
  width: 100%;
  max-width: $main-panel-width;
  margin: 0 auto;
  padding: 0.875rem;
  border-bottom: 1px solid $layout-border-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  z-index: $z-notification;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__action {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-weight: $font-bold;
    margin: 0 auto;
    width: 6.125rem;
  }
}
