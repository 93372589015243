@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.app-header {
  height: $header-height;
  min-height: $header-height;
  flex-shrink: 0;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: $black;
  z-index: $z-header;
  background-color: $white;
  transition: background-color 0.5s ease-in-out;
  box-shadow: $default-shadow;

  &.for-analytics {
    border-bottom: 1px solid $lightest-grey;
    @include narrow {
      border-bottom: none;
    }
  }

  @include narrow {
    color: $white;
    background-color: $dark-blue;
    height: $header-height-mobile;
    min-height: $header-height-mobile;
  }

  &__nav-toggle {
    z-index: $z-nav-toggle;
  }

  & &__back {
    border-radius: 50%;
  }

  &__right-buttons {
    display: flex;
    margin-left: auto;
    z-index: $z-header-button;
    align-items: center;
    font-size: $font-lg;
  }

  &__analytic-button,
  &__chat-button {
    position: relative;
    cursor: pointer;
    padding: 1rem;
    color: $blue;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $orange;
    }

    @include narrow {
      color: $white;
    }
  }

  &__chat-button-wrapper {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
  }

  &__chat-count {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(20%, -10%);
  }
}
