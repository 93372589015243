$colors: (
  'green': #068f68,
  'tip-green': #032838,
  'teal': #047f8c,
  'overlay-tip-green': rgba(#051219, 0.9),
  'overlay-green': rgba(#00733a, 0.85),
  'blue': #0b68ad,
  'dark-blue': #1a3e4e,
  'overlay-blue': rgba(#0b67ad, 0.8),
  'overlay-dark-blue': rgba(#1d3e4e, 0.85),
  'outline-blue': #4d90fe,
  'grey': #6a6a6a,
  'medium-grey': #7a7a7a,
  'medium-dark-grey': #7d7d7d,
  'light-grey': #aaa,
  'overlay-grey': rgba(#aaa, 0.8),
  'another-grey': #bababa,
  'lighter-grey': #b9b9b9,
  'lightest-grey': #efefef,
  'layout-border-grey': rgba(#b9b9b9, 0.4),
  'hover-grey': #e6e6e6,
  'black': #000,
  'light-black': rgba(#000, 0.7),
  'orange': #d95916,
  'overlay-orange': rgba(#ff5e3a, 0.85),
  'pink': #ff4981,
  'dark-pink': #d11250,
  'red': #cc0a05,
  'error-red': #f44336,
  'cyan': #5ac8fb,
  'dark-cyan': #047f8c,
  'yellow': #fcea1f,
  'white': #fff,
);

$green: map-get($colors, 'green');
$tip-green: map-get($colors, 'tip-green');
$teal: map-get($colors, 'teal');
$overlay-tip-green: map-get($colors, 'overlay-tip-green');
$overlay-green: map-get($colors, 'overlay-green');

$blue: map-get($colors, 'blue');
$dark-blue: map-get($colors, 'dark-blue');
$overlay-blue: map-get($colors, 'overlay-blue');
$overlay-dark-blue: map-get($colors, 'overlay-dark-blue');
$outline-blue: map-get($colors, 'outline-blue');

$grey: map-get($colors, 'grey');
$medium-grey: map-get($colors, 'medium-grey');
$medium-dark-grey: map-get($colors, 'medium-dark-grey');
$light-grey: map-get($colors, 'light-grey');
$overlay-grey: map-get($colors, 'overlay-grey');
$another-grey: map-get($colors, 'another-grey');
$lighter-grey: map-get($colors, 'lighter-grey');
$lightest-grey: map-get($colors, 'lightest-grey'); // default background color
$layout-border-grey: map-get($colors, 'layout-border-grey');
$hover-grey: map-get($colors, 'hover-grey');

$black: map-get($colors, 'black');
$light-black: map-get($colors, 'light-black');

$orange: map-get($colors, 'orange');
$overlay-orange: map-get($colors, 'overlay-orange');

$pink: map-get($colors, 'pink');
$dark-pink: map-get($colors, 'dark-pink');

$red: map-get($colors, 'red');
$error-red: map-get($colors, 'error-red');

$cyan: map-get($colors, 'cyan');
$dark-cyan: map-get($colors, 'dark-cyan');

$yellow: map-get($colors, 'yellow');
$white: map-get($colors, 'white');
