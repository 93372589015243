@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

$alert-width: 21.875rem;

@mixin tab-offset($prop: padding-top, $negative: false) {
  #{$prop}: if($negative, -$tab-height, $tab-height);
  @include mobile {
    #{$prop}: if($negative, -$tab-height-mobile, $tab-height-mobile);
  }
}

.timeline {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &.hidden {
    visibility: hidden;
    overflow-y: hidden;
  }

  &__fab-icon {
    @include fab-icon-plus;
  }

  &__container {
    max-width: 100%;
    height: 100%;
    @include tab-offset;
  }

  &__subscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__empty-message {
    color: $light-grey;
    font-size: $font-lg;
    line-height: 1.5;
    width: 100%;
    padding: 1rem;
    text-align: center;
  }

  &__add-icon {
    vertical-align: middle;
    background: $blue;
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0 0.5rem;
    color: $white;
    font-size: $font-sm;
  }

  &__header {
    margin: 0.875rem 0;
  }

  &__list {
    width: 100%;
    height: 100%;
  }

  &__virtual-list {
    box-sizing: content-box;
    @include tab-offset;
    @include tab-offset($prop: margin-top, $negative: true);
  }

  &__now {
    max-width: $main-panel-width;
    margin: 0 auto;
  }

  &__hide-classes {
    cursor: pointer;
    color: $blue;
  }

  &__action {
    display: flex;
    line-height: 1.5;
    cursor: pointer;
    max-width: $main-panel-width;
    margin: 0 auto;
    padding: 1.125rem 0.875rem;
    justify-content: space-between;
    border-bottom: 1px solid $lightest-grey;
    background-color: $white;
    @include button-hover-modifier;
  }

  &__action-title {
    display: flex;
    align-items: center;
    color: $blue;
    font-weight: $font-thick;
    @include children-margin;
  }

  &__activity-counters {
    color: $light-grey;
    display: flex;
    align-items: center;
    @include children-margin($space: 0.75rem, $dir: left);
  }

  &__activity-counter {
    display: inline-flex;
    align-items: center;
    @include children-margin($space: 0.4375rem, $dir: left);
    @include color-modifier('blue');
    @include color-modifier('red');
    @include color-modifier('orange');
  }

  &__course-complete {
    width: 100%;
    text-align: center;
    margin: 0.875rem 0;
    color: $grey;
  }

  &__footer {
    height: 0.875rem;
    color: transparent;
  }

  &__delete-lecture {
    width: $alert-width;

    &-title {
      color: $red;
    }

    &-overlay {
      background: $overlay-orange;
    }
  }

  &__tutorial-details {
    width: $alert-width;

    &-body {
      display: block;
      color: #1d2129;
      @include children-margin($space: 0.4375rem, $dir: bottom, $selector: p);
    }

    &-overlay {
      background: $overlay-orange;
    }

    &-action {
      display: inline-block;
      padding: 0;
    }
  }

  &__go-live {
    width: $alert-width;

    &-title {
      color: $orange;
    }

    &-overlay {
      background: $overlay-orange;
    }
  }

  &__bullet {
    width: 0.525rem;
    height: 0.525rem;
    border-radius: 50%;
    margin-right: 0.5rem;

    &-text {
      flex: 1;
    }

    &-container {
      display: flex;
      align-items: center;
    }
  }

  &__delete-tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $alert-width;

    &-overlay {
      background-color: $overlay-orange;
    }
  }

  &__filter-activities {
    width: $alert-width;

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__menu {
    padding: 0.875rem;
    color: $blue;
    cursor: pointer;
    text-transform: capitalize;
    @include button-hover-modifier;
  }

  &__notification {
    margin-left: unset;
    margin-right: unset;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &-wrapper {
      width: 100%;
      z-index: $z-notification;

      &.hidden {
        visibility: hidden;
      }
    }

    &.hidden {
      position: unset;
    }
  }

  &__add-new {
    margin-bottom: -0.875rem;
  }
}
