@import '../../core/colors';

.toggle-cell {
  padding: 0.5rem 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;

  &__helper-text {
    padding: 0.5rem 1rem 1rem;
    color: $grey;
    font-size: $font-xsm;

    &_danger {
      color: $error-red;
    }
  }
}
