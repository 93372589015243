@import '../../core/colors';

.new-comments-bubble {
  margin-top: -0.35rem;

  &__wrapper {
    position: relative;
  }

  & &__icon {
    font-size: 1.3125rem;
    color: $orange;
  }

  &__count {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    color: $white;
    font-weight: $font-bold;
    margin-top: 0.2625rem;
    font-size: 0.7rem;
  }
}
