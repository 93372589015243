@import '../../core/mixins';

$highlighter-height: 3px;

@mixin active-color($prop: color) {
  #{$prop}: $blue;
  @include mobile {
    #{$prop}: $white;
  }
}

@mixin inactive-color($prop: color) {
  #{$prop}: $light-grey;
  @include mobile {
    #{$prop}: rgba(255, 255, 255, 0.5);
  }
}

.sliding-tabs {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    width: 100%;
    justify-content: center;
    background-color: transparent;
    display: flex;
    overflow-y: visible;
    height: $tab-height;
  }

  &__tabs {
    height: inherit;
    width: $main-panel-width;
    max-width: 100%;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    border-top: none;
    align-items: center;
    z-index: $z-tabs;
    box-shadow: $default-shadow;
    border-top: 1px solid $lightest-grey;

    @include mobile {
      box-shadow: $dark-shadow;
      background-color: $dark-blue;
      border-top: none;
    }
  }

  &__tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    height: 100%;
    cursor: pointer;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    background-color: $white;
    transition: 0.5s color;

    @include inactive-color;
    @include mobile {
      flex-direction: column;
      background-color: $dark-blue;
    }

    &.active {
      @include active-color;
    }
  }

  &__tab-icon {
    font-size: $font-lg;
    @include inactive-color;
    @include mobile {
      font-size: $font-xlg;
      margin-bottom: 0.5rem;
    }
  }

  &__tab.active &__tab-icon {
    @include active-color;
  }

  &__tab-title {
    margin-left: 0.4375rem;
    text-transform: uppercase;
    @include mobile {
      margin-left: unset;
    }
  }

  &__highlighter-track {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    height: $highlighter-height;
    width: 100%;
  }

  &__highlighter {
    height: $highlighter-height;
    transition: margin-left 0.3s ease-out;
    @include active-color(background-color);
  }

  &__tab-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    &.scrollable {
      overflow: auto;
    }
  }

  @include mobile {
    z-index: $z-tabs-mobile;

    &.with-icons > &__wrapper {
      height: $tab-height-mobile;
    }
  }
}
