@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.raw-editor {
  &__upload-button {
    @include paper;
    color: $blue;
    padding: 0.5rem 2rem;
    cursor: pointer;
    margin: 0.75rem auto 0;
    text-align: center;
    width: 20rem;
    max-width: 95%;
    box-shadow: $default-shadow;
  }
}
