@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.loader {
  &__wrapper {
    position: relative;
  }

  &__spinner {
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    border: 4px solid;
    border-color: $orange $green $blue $dark-pink;
  }

  &__overlay {
    @include center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $lightest-grey;
    z-index: $z-loader;
  }

  &__full-screen {
    @include center;
    width: 100%;
    height: 100%;
  }
}
