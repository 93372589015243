.virtual-list {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__dummy-row {
    width: 100%;
    visibility: hidden;
  }
}
