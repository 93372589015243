@import '../../core/colors';
@import '../../core/variables';

.analytics-header {
  position: relative;
  align-items: center;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: $white;
  box-sizing: border-box;
  border-bottom: 1px solid $lightest-grey;
  z-index: $z-header;

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    color: $blue;
    transform: translateX(-50%) translateY(-50%);
  }

  &__icon {
    margin-right: 0.5rem;
    font-size: 1.4em;
  }
}
