@import '../../../core/colors';
@import '../../../core/mixins';
@import '../../../core/variables';

.home {
  &__user-widget {
    flex-shrink: 0;
    max-width: $home-content-width;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
      width: 100%;
      max-width: 100%;
      align-self: flex-start;
    }
  }

  &__course-header {
    font-size: $font-lg;
    @include mobile {
      font-size: $font-md;
      font-weight: $font-bold;
    }
  }

  &__referral {
    background-color: $green;
    color: $white;
    padding: 0.875rem 1.75rem;
    width: 100%;
    max-width: $home-content-width;
    text-align: center;
    font-weight: $font-bold;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    user-select: none;
    cursor: pointer;
    z-index: $z-header;
  }

  &__raised-button {
    color: $blue;
    border-radius: $border-radius-std;
    width: 100%;
    max-width: $home-content-width;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
  }

  &__fab-icon {
    @include fab-icon-plus;
  }

  &__archived-button-container {
    padding: 0 0.75rem 1rem;
    font-weight: $font-bold;
    width: 100%;
    flex-shrink: 0;
  }

  &__course-list-container {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
  }

  &__provisional-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $orange;
    border-radius: 4px;
    color: $white;
    padding: 0.5rem;
    margin: 0 0.75rem;
    max-width: calc(100% - 1.5rem);
    width: 640px;

    &__text {
      flex: 1;
    }

    &__more {
      flex-shrink: 0;
      background-color: transparent;
    }
  }
}

.demo-course-widget {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: $home-content-width;
  box-shadow: $default-shadow;
  background-color: $white;
  border-radius: $border-radius-std;
  line-height: 1.25rem;
  transition: box-shadow 0.3s linear;

  &:hover {
    box-shadow: $spread-shadow;
  }

  &__title {
    font-size: $font-md;
    font-weight: $font-bold;
    padding: 0.75rem 0.75rem 0.5rem;
  }

  &__section-header {
    font-size: $font-sm;
    font-weight: $font-bold;
    padding: 0 0.75rem;
  }

  &__list {
    margin: 0.25rem 0.75rem 0.5rem;
  }

  &__create {
    width: 100%;
    height: 48px;
    cursor: pointer;
    background-color: $dark-blue;
    color: #fff;
    font-size: $font-sm;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom-left-radius: $border-radius-std;
    border-bottom-right-radius: $border-radius-std;
  }
}
