@charset "UTF-8";

@import 'intl-tel-input/build/css/intlTelInput.min.css';

@import './colors';
@import './variables';

//==============
// global styles
//==============
@import './theme';

//=================
// component styles
//=================
@import '../acadly/common/Paper';
@import '../acadly/common/ActionBar';
@import '../acadly/common/RaisedButton';
@import '../acadly/common/Alert';
@import '../acadly/common/Dialog';
@import '../acadly/common/DivButton';
@import '../acadly/common/FlatButton';
@import '../acadly/common/FloatingActionBar';
@import '../acadly/common/FloatingActionButton';
@import '../acadly/common/LoaderButton';
@import '../acadly/common/Drawer';
@import '../acadly/common/IconButton';
@import '../acadly/common/SlidingTabs';
@import '../acadly/common/ContentView';
@import '../acadly/common/VirtualList';
@import '../acadly/common/NotificationBar';
@import '../acadly/common/DateView';
@import '../acadly/common/Avatar';
@import '../acadly/common/NewCommentsBubble';
@import '../acadly/common/FloatingMenu';
@import '../acadly/common/Loader';
@import '../acadly/common/RadioButton';
@import '../acadly/common/CheckBox';
@import '../acadly/common/QuestionOptionEditor';
@import '../acadly/common/ProgressBar';
@import '../acadly/common/Toggle';
@import '../acadly/common/SelectableAvatar';
@import '../acadly/common/SvgIcon';
@import '../acadly/pip-container/PipContainer';
@import '../acadly/pip-container/ZoomMeeting';
@import '../acadly/common/AutoSubmitDialog';
@import '../acadly/common/Toast';
@import '../acadly/common/Select';
@import '../acadly/common/Stepper';
@import '../acadly/common/MediaCell';
@import '../acadly/common/ToggleCell';
@import '../acadly/common/ActivityPrefsDialog';
@import '../acadly/common/QuickStartTips';
@import '../acadly/common/TextArea';
@import '../acadly/common/PhoneInput';
@import '../acadly/common/CtaButton';

//==================
// module components
//==================
@import '../acadly/Root';

@import '../acadly/app/App';
@import '../acadly/app/Sidebar';
@import '../acadly/app/Header';
@import '../acadly/app/ContextPanel/ContextPanel';
@import '../acadly/app/FAQScreen';
@import '../acadly/app/NewFeaturesDialog';
@import '../acadly/app/SettingsScreen';
@import '../acadly/app/Home/Home';
@import '../acadly/app/Home/FeedbackForm';
@import '../acadly/app/CourseWidget';
@import '../acadly/app/AnalyticsHeader';
@import '../acadly/app/CreateCourseScreen/CreateCourseScreen';
@import '../acadly/app/JoinCoursePayment/styles';

@import '../acadly/assignment/Analytics';
@import '../acadly/assignment/Assignment';
@import '../acadly/assignment/AssignmentWidget';
@import '../acadly/assignment/SuggestedAssignmentWidget';
@import '../acadly/assignment/SuggestedAssignmentDialog';
@import '../acadly/assignment/SuggestedAssignmentPreview';

@import '../acadly/class/Class';
@import '../acadly/class/Activities';
@import '../acadly/class/Agenda';
@import '../acadly/class/ClassWidget';
@import '../acadly/class/Attendance';
@import '../acadly/class/ClassAnalytics';
@import '../acadly/class/SuggestedActivitiyPreview';
@import '../acadly/class/SuggestedActivityWidget';
@import '../acadly/class/SuggestedPollQuestions';

@import '../acadly/announcement/Announcement';
@import '../acadly/announcement/NewAnnouncement';
@import '../acadly/announcement/AnnouncementWidget';

@import '../acadly/comments/Comment';
@import '../acadly/comments/Comments';

@import '../acadly/course/Course';
@import '../acadly/course/CourseAnalytics';
@import '../acadly/course/FilterActivities';
@import '../acadly/course/Info';
@import '../acadly/course/Syllabus';
@import '../acadly/course/Timeline';
@import '../acadly/course/TimelineWidget';
@import '../acadly/course/AddStudents/AddStudents';
@import '../acadly/course/Tutorial';

@import '../acadly/poll/Poll';
@import '../acadly/poll/Analytics';

@import '../acadly/query/Query';
@import '../acadly/query/NewQuery';
@import '../acadly/query/QueryWidget';

@import '../acadly/quiz/Quiz';
@import '../acadly/quiz/AttemptPage';
@import '../acadly/quiz/Analytics';
@import '../acadly/quiz/Questions';
@import '../acadly/quiz/DraggableQuestion';

@import '../acadly/discussion/Discussion';

@import '../acadly/resource/Resource';
@import '../acadly/resource/Analytics';

@import '../acadly/rich-text/RawEditor';

@import '../acadly/getin/GetInScreen';
