$border-color: $overlay-grey;
$border-radius: 4px;
$max-height: 320px;
$transition: 200ms cubic-bezier(0, 0, 0.2, 1);
$z-index: 4000;
$font-size: 1rem;

@keyframes show-dropdown {
  from {
    opacity: 0.5;
    transform: scale(0);
  }

  90% {
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.select {
  position: relative;
  padding-top: 18px;
  font-size: $font-size;
  overflow: hidden;

  &--no-floating-label {
    padding-top: 0;
  }

  &__label {
    color: $light-grey;
    padding: 0;
    font-size: inherit;
    font-weight: $font-thin;
    line-height: 1;
    letter-spacing: 0.00938em;
    top: 6px;
    left: 50%;
    width: 100%;
    position: absolute;
    transform-origin: top center;
    transform: translate(-50%, 24px) scale(1);
    transition: color $transition, top $transition, opacity $transition, transform $transition;
  }

  &--focused &__label {
    top: 0;
    color: $grey;
    transform: translate(-50%, 1.5px) scale(0.75);
  }

  &--left-aligned &__label {
    left: 0;
    transform-origin: top left;
    transform: translate(0, 24px) scale(1);
  }

  &--focused#{&}--left-aligned &__label {
    width: auto;
    transform: translate(0, 1.5px) scale(0.75);
  }

  &--has-error &__label {
    color: $error-red;
  }

  &--has-value:not(#{&}--focused) &__label {
    opacity: 0;
  }

  &--no-floating-label &__label {
    top: -11px;
    left: calc(50% + 10px);
  }

  &__button {
    position: relative;
    height: 42px;
    cursor: pointer;
    line-height: 42px;
    outline: none;
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: inherit;

    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid $light-grey;
      pointer-events: none;
    }

    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform $transition;
      border-bottom: 2px solid $blue;
      pointer-events: none;
    }

    &:focus::after {
      transform: scaleX(1);
    }
  }

  &--has-error &__button::after {
    border-bottom: 2px solid $error-red;
    transform: scaleX(1);
  }

  &__value {
    @include ellipsis;
    display: inline-block;
    width: calc(100% - 3em);
  }

  &__caret {
    top: calc(50% - 12px);
    color: rgba(0, 0, 0, 0.54);
    right: 0;
    position: absolute;
    pointer-events: none;
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }

  &--opened &__caret {
    transform: rotate(180deg);
  }

  &__options-overlay {
    position: fixed;
    z-index: $z-index;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
  }

  &__backdrop {
    z-index: -1;
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  &__option-container {
    position: fixed;
    z-index: 10000;
    margin: 0;
    padding: 8px 0;
    list-style: none;
    background: $white;
    border-radius: $border-radius;
    overflow: auto;
    max-height: $max-height;
    font-size: $font-size;
    animation: show-dropdown 267ms cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: top center;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &__search {
    margin: 6px 16px;
    padding: 8px;
    width: calc(100% - 32px);
    font-size: inherit;
    border: 1px solid $grey;
    border-radius: 4px;
  }

  &__option {
    margin: 0;
    padding: 6px 16px;
    color: rgba($color: $black, $alpha: 0.87);
    font-size: inherit;
    line-height: 1.5;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @include ellipsis;

    &:hover,
    &--active {
      background-color: rgba(0, 0, 0, 0.08);
    }

    &#{&}--selected {
      color: $white;
      background-color: $blue;
    }
  }
}
