@import '../../core/colors';
@import '../../core/variables';

$spacing: 1.5rem;

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: $white;
  background-color: $dark-blue;
  overflow-y: auto;

  &__logo {
    padding: $spacing;
    width: 100%;
    margin-bottom: $spacing;
    height: $sidebar-width;

    img {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing;
    cursor: pointer;
    font-size: $font-sm;
    flex-shrink: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }

    .icon {
      font-size: $font-xxlg;
      margin-bottom: 0.5rem;
    }
  }
}
