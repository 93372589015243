@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.assignment {
  &.attempting {
    display: none;
  }

  &__fab-icon {
    @include fab-icon-plus;
  }

  &__loader {
    @include paper;
    display: flex;
    justify-content: center;
    padding: 0.4375rem 0.875rem;
  }

  &__attempt {
    @include paper;
    width: 100%;
    height: 2rem;
    display: flex;
    padding: 0.4375rem 0.875rem;
    text-align: center;
    color: $blue;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__attempt-header {
    @include paper;
    width: 100%;
    padding: 0.875rem 0;
    margin-bottom: 0.4375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__raised-button {
    color: $blue;
    margin-top: 1rem;
    &.danger {
      color: $red;
    }
    &.invisible {
      visibility: hidden;
      width: 100%;
    }
  }

  &__avatar {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-right: 0.5rem;

    &--lg {
      width: 2.625rem;
      height: 2.625rem;
      margin-right: 0.4375rem;
    }
  }

  &__questions {
    margin: 0.5rem 0;

    &__title {
      color: $medium-grey;
      font-size: $font-xsm;
      font-weight: $font-thick;
      margin-top: 2rem;
    }

    &__no-questions {
      padding: 0 1rem;
      font-size: $font-sm;
      color: $light-grey;
    }
  }

  &__question {
    &__award {
      display: flex;
      align-items: center;
    }

    &__comment {
      cursor: pointer;
      color: $blue;
      padding: 0.25rem 0.5rem;
      margin-right: -0.5rem;
    }

    &__format-title {
      color: $grey;
      margin: 0.75rem 0 0.5rem;
      font-size: $font-xsm;
      text-transform: uppercase;
      font-weight: $font-bold;
    }

    &__formats {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__publish-subtitle {
    font-size: $font-xsm;
    color: $light-grey;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
  }

  &__publish-warning {
    padding: 0 0.875rem;
    margin: 0.5rem 0;
    color: $orange;
    font-size: $font-sm;
    display: flex;

    i {
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
