@import '../../core/mixins';
@import '../../core/variables';

.iframe-placeholder {
  min-height: 277.811px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  background-color: $white;
  color: $black;
  border: 1px solid #ddd;
  border-radius: 4px;

  &.center {
    text-align: center;
  }

  &__heading {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem;
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    width: 90%;
  }

  &__action {
    display: inline-block;
    margin: 20px auto 16px;
    padding: 14px 16px;
    cursor: pointer;
    background-color: #2d8cff;
    color: #fff;
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 900;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    border-radius: 100px;

    &.disabled {
      opacity: 0.5;
    }
  }

  &__add-button {
    cursor: pointer;
    height: 48px;
    max-width: 80%;
  }
}

.open-zoom {
  display: inline-block;
  text-align: center;
  background-color: #0e71eb;
  border-color: #0e71eb;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  padding: 5px 16px;
  height: 56px;
  line-height: 44px;
  font-size: 20px;
}

.class-activities {
  display: flex;
  flex-direction: column;
  padding-bottom: 5.25rem;
  padding-top: $tab-height;

  @include mobile {
    padding: $tab-height $spacing 5.25rem;
  }

  &.invisible {
    visibility: hidden;
  }

  .fa-pencil {
    color: $light-grey;
    font-size: $font-lg;

    &:hover {
      color: $blue;
    }
  }

  &__inline-icon {
    vertical-align: middle;
    margin: 0 0.25rem;
    color: $blue;
    font-size: $font-md;
  }

  &__avatar {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.7rem;
  }

  &__fab-icon {
    @include fab-icon-plus;
  }

  &__card {
    @include paper;
    margin: 0.5rem 0;
  }

  &__header {
    margin: 1rem 0 0.5rem;
    text-transform: uppercase;
    color: $grey;
    font-size: $font-sm;
  }

  &__placeholder {
    color: $light-grey;
    font-size: $font-sm;
    padding: 1rem;
  }

  &__icon-row {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    font-size: $font-lg;
    display: flex;
    align-items: center;

    @include color-modifier('red');
    @include color-modifier('green');

    &__icon {
      font-size: $font-xxlg;
    }

    &__label {
      margin-left: 0.5rem;
    }
  }

  &__schedule {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    font-size: $font-md;
    margin-bottom: 1px;

    &.editable {
      background-color: $white;
      cursor: pointer;
    }

    &.can-end {
      padding: 0.25rem 1rem;
      background-color: $red;
      color: $white;
    }

    &.can-start {
      padding: 0.25rem 1rem;
      background-color: $green;
      color: $white;
    }

    &.in-session:not(.can-end) {
      color: $green;
    }

    & &__icon {
      color: $green;
      font-size: $font-lg;
      margin-right: 1rem;
      min-width: 1.125rem;
      text-align: center;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }

    & &__action {
      margin-left: auto;
      padding: 0.25rem 0.5rem;
      transform: translateX(0.5rem);
    }

    &.can-end &__icon,
    &.can-start &__icon,
    &.can-end &__action,
    &.can-start &__action {
      color: $white;
    }
  }

  &__incharge {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;

    &.can-edit {
      cursor: pointer;
      background-color: $white;
    }

    & &__action {
      margin-left: auto;
      padding: 0.5rem;
      transform: translateX(0.5rem);
    }
  }

  &__summary {
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;

    &__icon {
      margin: 0 0.5rem;
      color: $grey;
    }
  }

  &__more-suggestions {
    background-color: $white;
    padding: 1rem;
    color: $blue;
    text-transform: uppercase;
    @include button-hover-modifier;
  }
}

.suggested-activities {
  @include main-panel;
  &__header {
    padding: 1rem 0;
    color: $grey;
    @include children-margin($spacing, 'bottom', 'div');

    @include mobile {
      padding: 1rem;
    }
  }
}

.recordings-cell {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: $white;
  color: $blue;
  width: 100%;
  border: none;

  &--is-read {
    color: $medium-dark-grey;
  }

  &--unpublished {
    color: $red;
  }

  &__icon {
    @include center;
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    font-size: 2em;

    svg {
      width: 28px;
    }
  }

  &--is-read &__icon {
    color: $light-grey;
  }

  &__title {
    font-weight: $font-thick;
    margin-right: 0.5rem;
    padding: 1rem 0;
  }

  .new-tag {
    display: flex;
    align-items: center;
    color: $orange;
    font-weight: 900;
    font-size: $font-xsm;

    i {
      margin-right: 0.25rem;
    }
  }
}

.meeting-recording-dialog {
  max-width: 60%;
  margin: auto;

  @include mobile {
    max-width: 100%;
  }

  .publish-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    background: $white;

    &__title {
      flex: 1;
    }
  }

  .recording {
    &__header {
      padding: 0.75rem 0.75rem 0.25rem;
    }

    &__thumbnail {
      color: $blue;
      width: 2rem;
      margin: 0.5rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__delete-button {
      color: $red;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
    }
  }
}

.delete-recording {
  &__title {
    color: $orange;
    font-size: $font-md;
    margin-bottom: 1rem;
  }

  &__option {
    display: flex;
    cursor: pointer;
    margin-top: 1rem;
  }

  &__checkbox {
    margin-right: 0.5rem;
  }
}
