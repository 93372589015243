@import '../../core/mixins';
@import '../../core/variables';

.activity-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__action-bar {
    @include main-panel;
    top: 0;
    position: sticky;
    z-index: $z-action-bar;
    display: flex;
    justify-content: space-around;
    background-color: $white;
    height: $action-bar-height;
    border-top: 1px solid $lightest-grey;
    box-shadow: $default-shadow;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    max-width: $main-panel-width;

    @include mobile {
      padding: 0 1rem 1rem;
    }
  }

  &__use-title {
    font-size: $font-lg;
    margin-bottom: 1rem;
    color: $orange;
  }

  &__use-option {
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    font-size: $font-md;

    &.disabled {
      color: $grey;
      cursor: default;
    }
  }

  &__radio-button {
    margin-right: 0.5rem;
  }

  &__use-option.disabled &__radio-button {
    color: $grey;
  }

  &__info {
    margin-top: 0.75rem;
  }
}
