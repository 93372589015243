@import '../../core/colors';

.class-page {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.class-incharge {
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 0 $spacing;
  }

  &__avatar {
    width: 1.75em;
    height: 1.75em;
    margin-right: 0.5rem;
  }

  &__heading {
    color: $light-grey;
    width: 100%;
    text-align: center;
    margin: 1rem 0 0.75rem;
  }

  &__option {
    display: flex;
    align-items: center;
    background-color: $white;
    margin-bottom: 1px;
    padding: 0.5rem 1rem;

    & &__check {
      margin-left: auto;
      font-size: $font-xlg;
      color: $light-grey;
    }

    &.selected &__check {
      color: $green;
    }

    & &__title {
      color: $dark-blue;
      font-size: $font-md;
    }

    &.selected &__title {
      color: $green;
    }
  }

  &__note {
    color: $light-grey;
    margin: 0.5rem 0 1rem;
  }
}
