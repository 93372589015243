@import '../../core/colors';
@import '../../core/variables';

.floating-action-button {
  border-radius: 100%;
  position: fixed;
  bottom: 1.75rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: $blue;
  cursor: pointer;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-xlg;
  z-index: $z-fab;
  box-shadow: $dark-shadow;

  &.mobile {
    bottom: 0.875rem;
  }

  &.disabled {
    cursor: default;
    color: $light-grey;
  }

  &.bottom-right {
    right: calc(#{$context-panel-width} + 1.75rem);
  }

  &.narrow-screen.bottom-right {
    right: 1.75rem;
  }

  &.mobile.bottom-right {
    right: 0.875rem;
  }

  &.bottom-left {
    left: calc(#{$sidebar-width} + 1.75rem);
  }

  &.narrow-screen.bottom-left {
    left: 1.75rem;
  }

  &.mobile.bottom-left {
    left: 0.875rem;
  }
}
