@import '../../core/variables';

.floating-action-bar {
  border-radius: 1.75rem;
  box-shadow: $default-shadow;
  position: fixed;
  bottom: 1.75rem;
  width: 7.875rem;
  height: 2.375rem;
  background-color: $white;
  cursor: pointer;
  color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-lg;

  &.mobile {
    bottom: 0.875rem;
  }

  &.disabled {
    cursor: default;
    color: $light-grey;
  }

  &.bottom-right {
    right: calc(#{$context-panel-width} + 1.75rem);
  }

  &.narrow-screen.bottom-right {
    right: 1.75rem;
  }

  &.mobile.bottom-right {
    right: 0.875rem;
  }

  &.bottom-left {
    left: calc(#{$sidebar-width} + 1.75rem);
  }

  &.narrow-screen.bottom-left {
    left: 1.75rem;
  }

  &.mobile.bottom-left {
    left: 0.875rem;
  }
}
