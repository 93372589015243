@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

$width: 450px;

.drop-area {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border: 4px dashed $blue;
  border-radius: 4px;
  opacity: 0.7;
  display: none;
  align-items: center;
  justify-content: center;
  width: $width;

  @include mobile {
    width: calc(100% - 2rem);
  }
}

.draggable-question {
  position: absolute;
  padding: 0.75rem;
  border-top-left-radius: $border-radius-std;
  border-top-right-radius: $border-radius-std;
  background-color: $white;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 1px 2px);
  left: 50%;
  transform: translateX(-50%);
  width: $width;
  user-select: none;
  will-change: transform, left, top;
  contain: layout;

  &:not(.dragging) {
    transition: top 0.25s ease-in-out;
  }

  @include mobile {
    width: calc(100% - 2rem);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0.5em; // desired tooth height
    // outline: 1px solid red; // use this for testing
    background: linear-gradient(135deg, $white 33.333%, transparent 0),
      linear-gradient(-135deg, $white 33.333%, transparent 0);
    background-position: center; // used for symmetrical edges
    background-size: 1em 100%; // width (1st num) must be twice tooth height
  }

  &__content {
    overflow: hidden;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    font-size: $font-md;
    font-weight: $font-thick;

    .drag-handle {
      cursor: move;
      min-width: 1.5rem;
      color: $light-grey;
    }
  }
}
