@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.action-bar {
  @include paper;
  @include main-panel;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: $action-bar-height;
  background-color: $white;
  top: 0;
  z-index: $z-action-bar;
  border-top: 1px solid $lightest-grey;
  box-shadow: $default-shadow;

  &:focus {
    z-index: $z-action-bar;
  }

  &.ipad,
  &.mobile {
    left: 50%;
    transform: translateX(-50%);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.4375rem 0.875rem;
    width: 3.5rem;

    &.blue {
      color: $blue;
    }

    &.orange {
      color: $orange;
    }

    &.red {
      color: $red;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 4rem;
    font-size: $font-lg;
  }

  &__label {
    text-align: center;
    font-size: $font-xsm;
  }
}
