$radio-button-size: 1.25rem;

.radio-button {
  width: $radio-button-size;
  height: $radio-button-size;
  border-radius: 100%;
  padding: 2px;
  border: 2px solid currentColor;
  display: flex;
  color: $blue;

  &__content {
    width: 0;
    height: 0;
    border-radius: 100%;
    margin: auto;
    background-color: currentColor;
    transition: all 0.2s;
  }

  &.selected &__content {
    width: 100%;
    height: 100%;
  }

  &:not(.disabled) {
    cursor: pointer;
  }
}
