@import '../../core/colors';

.query {
  &__raised-button {
    color: $blue;
    margin-top: 1rem;

    &.danger {
      color: $red;
    }
  }

  &__warning {
    color: $orange;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
    }
  }

  &__student-count {
    padding: 1rem;
    margin-top: 1rem;
    background-color: $white;
  }
}
