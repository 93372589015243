@import '../../core/colors';
@import '../../core/mixins';

.question-option-editor {
  background-color: $white;
  margin-bottom: 1px;
  padding: 1rem;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__move {
    display: flex;
    align-items: center;

    .up,
    .down {
      width: 24px;
      height: auto;
      margin-right: 0.5rem;
      border-radius: 4px;
      border: 1px solid currentColor;

      &:not(:disabled) {
        color: $blue;
        cursor: pointer;
        border-color: $blue;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: $font-lg;
  }

  &__action {
    cursor: pointer;
    margin-left: 0.4375rem;
    color: $dark-blue;
    @include color-modifier('red');
  }

  &__error {
    color: $red;
    margin-left: 0.4375rem;
  }
}
