@import '../../core/colors';
@import '../../core/variables';

.selectable-avatar {
  position: relative;

  &__image {
    max-width: 100%;
    max-height: 100%;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: $border-radius-std;
  }

  &.selected &__image {
    opacity: 0.3;
  }

  &__check {
    color: $black;
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
  }
}
