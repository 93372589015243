@import '../../core/variables';

.timeline-widget {
  display: flex;
  align-items: center;
  margin: 0 auto 1px;
  max-width: $main-panel-width;
  width: 100%;
  min-height: $timeline-widget-height;

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $timeline-widget-icon;
    margin-right: 1.25rem;
  }
}
