@import '../../core/variables';

.svg-icon {
  display: inline-flex;
  border: none;
  background-color: transparent;
  text-transform: none;

  svg {
    width: 100%;
    height: auto;
  }

  &:not(.no-fill) {
    svg {
      @include path-fill;
    }
  }
}
