@import '../../core/mixins';

.quiz-analytics {
  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
  }

  &__submission {
    @include paper;
    padding: 0.4375rem;
    margin-bottom: 0.4375rem;
    cursor: pointer;
    width: 100%;
    display: flex;
    text-align: left;
  }

  &__submission-view {
    @include paper;
    padding: 0.4375rem 0.875rem;
    margin: 1rem;
  }
}
