@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.assignment-widget {
  width: 100%;
  display: flex;
  height: $timeline-widget-height;
  cursor: pointer;
  padding: 0.4375rem 0.875rem;
  background-color: $white;
  position: relative;
  border-bottom: 2px solid transparent;

  &:focus {
    outline: 2px solid $outline-blue;
    outline-offset: -2px;
    z-index: $z-timeline-widget;
  }

  &:active {
    outline: 0;
    outline-offset: 0;
    z-index: $z-timeline-widget;
  }

  &:hover {
    border-bottom: 2px solid $blue;
  }

  &__wrapper {
    display: flex;
    max-width: 80%;
    flex-direction: column;
  }

  &__heading {
    font-weight: $font-bold;
    display: flex;
    align-items: center;
    margin-bottom: 0.4375rem;
  }

  &__comments {
    margin-left: 0.4375rem;
  }

  &__updated-tag {
    color: $grey;
    margin-left: 0.4375rem;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 0.3rem;
    }

    &-text {
      font-size: $font-xsm;
      font-weight: 300;
    }
  }

  &__star {
    margin-left: 0.3rem;
    font-size: 1.3125rem;
  }

  &__title {
    @include ellipsis;
    margin-bottom: 0.4375rem;
    max-width: 100%;
  }
}
