@import '../../core/colors';
@import '../../core/variables';

.drawer {
  z-index: $z-drawer;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: all 0.2s ease-in-out;

  &.left {
    left: 0;
    transform: translateX(-100%);
  }

  &.open.left {
    transform: translateX(0);
  }

  &.right {
    right: 0;
    transform: translateX(100%);
  }

  &.open.right {
    transform: translateX(0);
  }

  &__placeholder {
    width: 100%;
    height: 100%;
  }
}
