@import '../../core/mixins';
@import '../../core/colors';
@import '../../core/variables';

.poll {
  &__manual-stop {
    padding: 0.5rem 0.875rem;
    background-color: $red;
    color: $white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      margin: 0.5rem 0;
    }

    &__note {
      font-size: $font-xsm;
      color: $light-grey;
      padding-top: 0.5rem;
    }

    &__button {
      margin-left: auto;
      padding: 0.25rem 0.5rem;
      transform: translateX(0.5rem);
    }
  }

  &__raised-button {
    visibility: hidden;
    width: 100%;
  }

  &__attempt-navigation {
    @include paper;
    box-shadow: $default-shadow;
    width: $main-panel-width;
    display: flex;
    padding: 0.4375rem 0.875rem;
    text-align: center;
    color: $red;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    &.ipad,
    &.mobile {
      width: 100%;
      position: fixed;
    }
  }

  &__question-loader {
    @include center;
    margin-top: 1.5rem;
    height: 3.5rem;
    width: 100%;
  }

  &__option {
    width: 100%;
    padding: 0.5rem 0;
    border-bottom: 1px solid $lightest-grey;
    color: $light-grey;

    &:last-child {
      border-bottom: none;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    &__title {
      display: flex;
      align-items: center;
      font-weight: $font-bold;
      color: $grey;
    }

    &.selected &__title {
      color: $blue;
    }

    &__progress {
      background-color: transparent;
      border-radius: 0;
      height: 1rem;
      margin-left: 0.875rem;
      flex: 1;
    }

    &__progress-metrics {
      display: flex;
      justify-content: flex-end;
      color: $blue;
      width: 7rem;
    }
  }

  &__add-question {
    &__details {
      background-color: $white;
      padding-bottom: 0.4375rem;
      margin-top: 1rem;

      @include mobile {
        margin-top: 0;
        padding-bottom: 0;
      }
    }

    &__title {
      padding: 0.5rem;
      margin-top: $spacing;
      color: $light-grey;
      font-size: $font-xsm;
      text-transform: uppercase;
    }

    &__options {
      margin: $spacing 0;
      color: $grey;
    }

    &__button {
      box-shadow: $default-shadow;
      background-color: $white;
      padding: $spacing;
      margin-top: 1rem;
      color: $blue;
      @include children-margin(0.5rem);
    }
  }
}
