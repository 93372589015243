@import '../../core/mixins';

.new-query {
  @include mobile {
    padding: 0 0.75rem;
  }

  &__warning {
    color: $orange;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
    }
  }
}
