@import '../../../core/mixins';
@import '../../../core/colors';
@import '../../../core/variables';

.context-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $lightest-grey;
  overflow: hidden;

  &__header {
    height: $header-height;
    z-index: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    box-shadow: $default-shadow;
    background-color: $white;
    color: $blue;
    flex-shrink: 0;

    @include narrow {
      height: $header-height-mobile;
    }

    @include mobile {
      color: $white;
      background-color: $dark-blue;
    }
  }

  &__content {
    height: calc(100% - #{$header-height});

    @include narrow {
      height: calc(100% - #{$header-height-mobile});
    }
  }

  &__toggle {
    color: $another-grey;
    position: absolute;
    cursor: pointer;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%) rotate3d(0, 1, 0, 0deg);
    font-size: $font-sm;
    transition: transform 0.5s ease-in-out;
    padding: 0.75rem;
  }

  & &__close {
    font-size: $font-md;
    padding: 0.5rem;
    border-radius: 50%;
    margin-left: 0.4375rem;
    cursor: pointer;
    position: absolute;
    left: 0;
  }

  &.expanded &__toggle {
    transform: translateY(-50%) rotate3d(0, 1, 0, 180deg);
  }

  &__title-icon {
    margin-right: 0.5rem;
    font-size: $font-sm;
  }

  &__title {
    font-weight: $font-thick;
    color: $blue;
    font-size: $font-md;

    @include mobile {
      color: $white;
      font-size: $font-sm;
    }
  }
}
