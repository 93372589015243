@import '../../core/variables';
@import '../../core/colors';

.alert {
  box-shadow: $dark-shadow;
  background-color: $white;
  padding: 0.4375rem 0.875rem;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-std;
  margin: auto;
  width: 43.75rem;
  max-width: 80%;
  max-height: 80%;
  animation-duration: 0.5s;

  &.center {
    text-align: center;
  }

  &.fixed {
    position: fixed;
    bottom: auto;
  }

  &__presentation {
    position: fixed;
    z-index: $z-alert-overlay;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: 100%;
    animation-duration: 0.5s;
    display: flex;
  }

  &__overlay {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    font-size: $font-lg;

    &.center {
      justify-content: center;
    }
  }

  &__body {
    padding-bottom: 0.875rem;
    width: 100%;
    overflow-y: auto;

    &.no-title {
      padding-top: 0.875rem;
    }
  }

  &__actions {
    display: flex;

    &.inline {
      width: 100%;
      margin-top: auto;
      padding-top: 0.5rem;
      justify-content: flex-end;
    }

    &.block {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
