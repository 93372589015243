@import '../../core/colors';

.div-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4375rem 0.875rem;
  cursor: pointer;

  &.disabled {
    color: $light-grey;
    cursor: default;
    pointer-events: none;
  }
}
