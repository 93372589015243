@import '../../core/colors';
@import '../../core/variables';

.filter-activities {
  &__heading {
    background: $white;
    box-shadow: $default-shadow;
    padding: 0.875rem;
    font-weight: normal;
    font-size: $font-md;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $lightest-grey;
  }

  &__category {
    font-weight: $font-thick;
    font-size: $font-sm;
    color: $medium-grey;
    padding: 0.5rem;
    margin-top: 1rem;
  }
}
