@import '../../core/colors';
@import '../../core/variables';

.textarea {
  margin-bottom: 0.5rem;

  &__field {
    width: 100%;
    border: none;
    resize: none;
    height: 88px;
    font-size: inherit;
    background-color: $white;

    &:focus {
      outline: none;
      border-color: $blue;
    }

    &::before {
      content: ' ';
      border-bottom: 2px solid $light-grey;
    }

    &::after {
      content: ' ';
      width: 0;
      height: 2px;
      transition: width 0.5s ease-in-out;
    }

    &:focus::after {
      width: 100%;
    }
  }

  &__border {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      content: ' ';
      display: block;
      height: 2px;
    }

    &::before {
      left: 0;
      width: 100%;
      background-color: $light-grey;
    }

    &::after {
      width: 0;
      left: 50%;
      height: 2px;
      background-color: $blue;
      transform: translateX(-50%);
      transition: width 0.2s ease-in-out;
    }
  }

  &.has-error &__border::after {
    background-color: $error-red;
  }

  &__field:focus + &__border::after {
    width: 100%;
  }

  &__length {
    font-size: $font-sm;
    text-align: right;
  }
}
