@import '../../core/colors';
@import '../../core/variables';

.avatar {
  max-width: 100%;
  max-height: 100%;
  border-radius: $border-radius-std;
  width: 3.5rem;
  height: 3.5rem;

  &.with-letter {
    background-color: $overlay-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-md;
  }
}
