.loader-button {
  position: relative;

  &.loading &__label {
    visibility: hidden;
  }

  &__wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.loading &__wrapper {
    display: flex;
  }
}
