@import '../../core/mixins';
@import '../../core/colors';

$toggle-width: 2.5rem;
$toggle-height: 1.5rem;

.toggle {
  @include center;
  width: $toggle-width;
  height: $toggle-height;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &__slider {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #999;
    border-radius: 3rem;
  }

  &.disabled &__slider {
    background-color: $lighter-grey;
  }

  &.selected &__slider {
    background-color: $green;
  }

  &__button {
    transition: all 0.2s ease-in-out;
    position: absolute;
    width: $toggle-width;
    height: $toggle-height;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    box-shadow: #666 1px 1px 5px 0;
    border-radius: 100%;
    background-color: $white;
  }

  &.disabled &__button {
    box-shadow: #666 0px 0px 1px 0px;
  }

  &.selected &__button {
    left: 1rem;
  }
}
