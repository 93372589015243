@import '../../../core/mixins';
@import '../../../core/colors';
@import '../../../core/variables';

.feedback-form {
  background-color: $white;
  font-size: 15px;
  line-height: 1.43;

  & > div {
    padding: 0 0.5rem;
  }

  &__title {
    padding: 0.5rem;
  }

  &.continued &__description {
    margin-bottom: 1rem;

    @include mobile {
      margin-top: 1rem;
    }
  }

  &__question-title {
    margin-bottom: 0.25rem;
    font-weight: $font-bold;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.33;
  }

  &__required {
    color: $red;
    margin-left: 0.5rem;
  }

  &__question-description {
    margin-bottom: 0.25rem;
  }

  &__divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0.5rem -0.5rem;
    width: calc(100% + 1rem);
  }

  &__question:last-child &__divider {
    display: none;
  }

  &__option {
    padding: 0.625rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__option-selector {
    margin-right: 0.625rem;
    align-self: flex-start;
    flex-shrink: 0;
  }

  &__rating-text {
    color: $blue;
    font-size: $font-sm;
    font-weight: $font-thick;
  }

  &__text-area {
    height: 88px;
    resize: none;
  }
}
