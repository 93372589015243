@import '../../core/variables';

.flat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: $border-radius-std;
  text-align: center;
  position: relative;

  &.primary {
    color: $blue;
  }

  &.secondary {
    color: $orange;
  }

  &.disabled {
    color: $light-grey;
    cursor: default;
  }

  &:not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__label {
    text-transform: uppercase;
    padding: 0.4375rem 0.875rem;

    &.invisible {
      visibility: hidden;
    }
  }

  &__loader-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
