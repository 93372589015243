.tutorial {
  margin-top: 0.4375rem;

  &__help {
    margin-top: 1.75rem;

    &__title {
      font-size: $font-lg;
      color: $green;
      margin-bottom: 0.875rem;
    }
  }

  &__acadly {
    display: flex;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.875rem;
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375rem;
  }

  &__bullet {
    margin-bottom: 0.4375rem;
  }

  &__line,
  &__para {
    margin: 0;
    margin-bottom: 0.875rem;
  }

  &__sentence {
    margin-right: 0.21875rem;
  }

  &__join-code {
    text-align: center;
    font-weight: $font-bold;
    font-size: $font-xlg;
    padding: 0.875rem 1.3125rem;
  }
}
