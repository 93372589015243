@import 'css/crash-report.css';

@font-face {
  font-family: 'acadly-icons';
  src: url('../static/fonts/acadly-fonticon/acadly.ttf');
}

* :not(ul) {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.no-focus:focus {
  outline: 0;
}

*:not(.no-focus):focus {
  outline: 2px solid rgb(77, 144, 254);
  outline-offset: -1px;
  /* z-index: 1; */
}
*:active {
  outline: 0;
}

* {
  box-sizing: border-box;
}
.text-field-input {
  -moz-appearance: textfield;
}

ul {
  padding-left: 2em;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
textarea {
  font-family: 'Lato', sans-serif;
}

/* For small screens */
@media only screen and (max-device-width: 350px) and (orientation: portrait) {
  body {
    font-size: 12px;
  }
}

.acadly-font {
  font-family: 'Lato', sans-serif;
}

html {
  height: 100%;
}

.pushable {
  transition: transform 0.5s;
}
.pushable:active {
  transform: translateX(0.1em) translateY(0.1em);
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.glyph-icon::after {
  font-family: 'acadly-icons';
  line-height: 1em;
  content: attr(data-icon);
}

.notify {
  position: absolute;
  background: url(https://s3.amazonaws.com/static.acad.ly/img/comment@2x.png) no-repeat;
  background-size: cover;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.3em;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
}
.notify.star {
  width: 0.7em;
  height: 0.7em;
  background: url(https://s3.amazonaws.com/static.acad.ly/img/new.png) no-repeat;
  background-size: cover;
}

.star {
  width: 1em;
  height: 1em;
  background: url(https://s3.amazonaws.com/static.acad.ly/img/new.png) no-repeat;
  background-size: cover;
}

.flat-button {
  transition: background-color 0.2s ease-in-out;
}

.flat-button:hover .flat-button-underline {
  width: 100%;
}

.flat-button .flat-button-underline {
  transition: width 0.2s ease-in-out;
}

.flat-button-underline {
  width: 0%;
}

.date-picker input {
  color: #ddd !important;
  border: none !important;
}

.text-field input {
  transition: border-bottom 0.2s ease-in-out;
}

.shadow {
  box-shadow: #999 1px 1px 5px 0px;
}

.flatpickr-calendar.open {
  box-shadow: #666 1px 1px 5px 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='date'] {
  border: 0px solid transparent;
  background-color: transparent;
  outline: none;
  color: transparent !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type='date']:invalid::-webkit-datetime-edit {
  color: transparent;
}

*:not(input) {
  -webkit-touch-callout: none;
  /*    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
*/
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.dp-modal {
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
}

.dp-today {
  display: none;
}

.dp-clear {
  display: none;
}

.dp-cal-month {
  cursor: default;
}

.dp-cal-month:hover {
  background: white;
  color: #3b404d;
}

.dp-cal-year {
  cursor: default;
}

.dp-cal-year:hover {
  background: white;
  color: #3b404d;
}

.dp-cal-header {
  background: white;
}

.dp-cal-footer {
  background: white;
}

.dp-close {
  font-weight: bold;
  color: #d95916;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.3em;
  width: initial;
  margin-bottom: 0.5em;
  border-bottom: 0.3em solid transparent;
  transition: 0.5s border-bottom;
}

.dp-close:hover {
  background: white;
  color: #d95916;
  border-bottom: 0.3em solid #d95916;
}

.dp:before {
  display: none;
}

.loader {
  animation: spin 1s linear infinite;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.file-icon {
  background: #0b68ad;
  height: 2.4em;
  width: 1.6em;
  font-family: Arial, Tahoma, sans-serif;
  font-weight: 300;
  display: block;
  width: 24px;
  height: 32px;
  position: relative;
  border-radius: 2px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}

.file-icon:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom-left-radius: 2px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff #fff hsla(0, 0%, 100%, 0.35) hsla(0, 0%, 100%, 0.35);
}

.file-icon:after {
  display: block;
  font-family: lato;
  content: attr(data-type);
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  color: #fff;
  text-transform: lowercase;
  width: 100%;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
}

div#MathJax_Message {
  display: none !important;
  background: white !important;
  color: transparent !important;
  max-height: 0px !important;
  max-width: 0px !important;
  font-size: 0px !important;
  border: 0px !important;
  padding: 0px !important;
}

.spotlight {
  background-color: grey;
  position: fixed;
}

.tip-wrapper-1 {
  background-color: rgba(5, 18, 25, 0.9) !important;
}

/* input::-webkit-slider-thumb {
    background-color: orange;
    border: solid 12px gold;
    border-radius: 0;

    height: 25px;
    width: 25px;
    border-radius: 50%;
    -webkit-appearance: none;
  } */

input[type='range'].range-slider {
  -webkit-appearance: none;
  /* background: transparent; */
  width: 100%;
  border-radius: 8px;
  outline-offset: 10px;
  height: 4px;
  transition: background 450ms ease-in;
  background: linear-gradient(
    to right,
    rgb(8, 129, 228) 0%,
    rgb(8, 129, 228) 0%,
    grey 0%,
    grey 100%
  );
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* background: #fff; */
  background-color: rgb(255, 255, 255);
  height: 20px;
  width: 20px;
  margin-top: -9px;

  /* border: 1px solid rgb(48, 45, 45); */
  border-radius: 99px;
  box-shadow: 0px 1px 2px 1px rgb(48, 45, 45);
}
input[type='range']::-webkit-slider-runnable-track {
  width: 500px;
  height: 2px;
  /* background: grey; */
  /* margin-bottom: 2rem; */
}
input[type='range']::-moz-range-progress {
  width: 500px;
  height: 4px;
  background-color: rgb(8, 129, 228);
  /* height: 4px; */
}
input[type='range']::-ms-fill-upper {
  width: 500px;
  background-color: rgb(8, 129, 228);
}
input[type='range']::-ms-fill-lower {
  width: 500px;
  background-color: grey;
}

/* background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.13)),to(hsla(0,0%,100%,0))); */

.thank {
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 22% 0px;
  width: 30px;
  height: 30px;
  background-size: 950%;
  margin: 0;
}
.unthank {
  width: 30px;
  height: 30px;
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 33% -0.5%;
  background-size: 950%;
  margin: 0;
}
.helpful {
  width: 30px;
  height: 30px;
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 0 0;
  margin: 0;
  background-size: 950%;
}
.unhelpful {
  width: 30px;
  height: 30px;
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 10% -0.5%;
  margin: 0;
  background-size: 950%;
}
.approved {
  width: 30px;
  height: 30px;
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 42.5% -0.5%;
  margin-left: -7px;
  /* margin: 0; */
  background-size: 950%;
}
.approved1 {
  width: 40px;
  height: 40px;
  background: transparent url('https://s3.amazonaws.com/static.acad.ly/img/icon-sprite.png')
    no-repeat 42.5% -0.5%;
  margin: 0;
  background-size: 950%;
}

.acadly-icon {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.dialog-header:focus {
  outline: none;
}
/* .div-button:hover {
    transition: background-color 0.1s ease-in-out;
    background-color: #ddd;
} */

/* .cancel-button:focus {
    outline: 1.5px solid rgb(77, 144, 254);
    outline-offset: -1.5px;
} */
/* input[type="file"].has-focus + #browse,
input[type="file"]:focus + #browse {
  background-color: #0287e8;
  outline: 1px dotted #000;
} */
/* .title-container:focus {
    outline: none
} */
/*

*:focus {
    outline: 0px;
    box-shadow: 0px 0px 2px 3px rgba(11, 104, 173,0.5) !important;
} */

/* Accessibility styles */

/* *:focus {
    outline: 0;
} */
.capitalize {
  text-transform: capitalize;
}

/* ribbon styles */
.ribbon {
  position: fixed;
  color: #fff;
  cursor: pointer;
}

.ribbon span {
  position: relative;
  display: block;
  text-align: center;
  background: #d95916;
  font-size: 13px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-top-right-radius: 8px;
  width: 126px;
  font-weight: bold;
  box-shadow: 0px 3px 6px 0px #8a8a8a;
  text-transform: uppercase;
}

.ribbon span::before {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #d95916;
}

.ribbon span::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 8px;
  left: -8px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #c02031;
}

.attendance-gif {
  padding: 2rem;
  height: 6rem;
  background: url('https://s3.amazonaws.com/static.acad.ly/img/att1.png') no-repeat center;
  background-size: auto 100%;
  animation: attendance-gif 1s linear infinite;
}

@keyframes attendance-gif {
  0% {
    background-image: url('https://s3.amazonaws.com/static.acad.ly/img/att1.png');
  }

  20% {
    background-image: url('https://s3.amazonaws.com/static.acad.ly/img/att2.png');
  }

  40% {
    background-image: url('https://s3.amazonaws.com/static.acad.ly/img/att3.png');
  }

  80% {
    background-image: url('https://s3.amazonaws.com/static.acad.ly/img/att4.png');
  }
}
