@import '../../core/colors';
@import '../../core/variables';

.date-view {
  text-align: center;
  width: 4.2em;
  max-width: 100%;

  &--compact {
    width: 3rem;
  }

  &__month {
    color: $white;
    font-weight: $font-thick;
    padding: 0.175rem 0.875rem;
    font-size: $font-xsm;
  }

  &--compact &__month {
    padding: 0.125rem 0.625rem;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-xxlg;
    height: 2rem;
    font-weight: $font-thick;
  }

  &--compact &__date {
    font-size: $font-lg;
    height: 1.5rem;
  }

  &__day {
    height: 1rem;
    font-weight: 900;
  }

  &--compact &__day {
    font-size: $font-xsm;
    line-height: 1.5;
  }
}
