.media-cell {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: $white;
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    width: 2rem;
    margin-right: 0.5rem;
    @include color-modifier('red');

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }

  &__title {
    flex: 1;
    font-size: 1rem;
    margin: 0 0.5rem;
    @include ellipsis;
  }
}
