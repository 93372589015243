@import '../../core/colors';

$animation-time: 150ms;
$border-radius: 4px;

.zoom-meeting {
  &__info-bar {
    background-color: rgba($color: $orange, $alpha: 0.9);
    color: $white;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    border-radius: 4px;

    &-message {
      flex: 1;
    }

    &-close {
      cursor: pointer;
      padding: 0.5rem;
      flex-shrink: 0;
    }
  }

  &__iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  .queries-overlay {
    position: absolute;
    top: 20px;
    left: 50%;
    background: $white;
    bottom: 50px;
    width: 300px;
    max-width: 100%;
    transform: translateX(-50%);

    &__close {
      cursor: pointer;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1rem;
    }

    &__body {
      max-height: calc(100% - 50px);
      overflow: auto;
    }
  }

  &:fullscreen .queries-overlay {
    right: 0;
    transform: translateY(-50%);
    left: unset;
    max-height: 50%;
    top: 50%;
  }

  .student-floating-action {
    &.raised {
      background-color: $red;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__status {
    margin-right: auto;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .footer-action {
    cursor: pointer;
    height: 32px;
    min-width: 48px;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    &.blue {
      border: 1px solid $blue;
      color: $blue;

      &:hover {
        background-color: $blue;
        color: $white;
      }
    }

    &.red {
      background-color: $red;
      color: $white;
    }

    &.green {
      background-color: $green;
      color: $white;
    }
  }

  .zm-alert {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35em;
    max-width: 80%;
    max-height: 90%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 1px 4px $black;

    &__title {
      padding: 0.75rem;
      color: $orange;
      font-size: $font-lg;
    }

    &__body {
      padding: 0 0.75rem;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem 0.75rem;
    }
  }
}
