@import '../core/colors';
@import '../core/variables';

.snackbar {
  position: fixed;
  bottom: -100%;
  z-index: $z-snakebar;
  overflow: hidden;
  background-color: rgb(44, 44, 44);
  color: $yellow;
  width: 100%;
  padding: 0.875rem;
  transition: bottom 0.5s ease;
  display: flex;

  &.visible {
    bottom: 0;
  }

  &__message {
    margin-right: 0.4375rem;
    flex: 1;
  }

  // double ampersand to increase the specificity
  & &__action {
    margin-left: auto;
    color: $white;
  }
}

.server-error {
  z-index: $z-server-error;
}

.root {
  width: 100%;
  height: 100%;
}
