.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  width: 320px;
  height: 48px;
  max-width: 100%;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: $font-thick;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 4px 0 rgba(0, 0, 0, 0.14);

  &.red {
    background-color: $red;
  }

  &.blue {
    background-color: $blue;
  }

  &.green {
    background-color: $green;
  }

  &.grey {
    color: #333;
    background-color: $lightest-grey;
  }

  &[disabled] {
    color: $medium-grey;
    background-color: #dadada;
    box-shadow: none;
    cursor: default;
  }

  @include mobile {
    font-size: $font-xsm;
    width: 100%;
  }
}
