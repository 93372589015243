@import '../../core/colors';
@import '../../core/variables';

$header-height: 3.75rem;

.suggested-assignment-screen {
  width: 100%;
  z-index: $z-create-assignment-screen;
  height: 100%;
  background-color: $lightest-grey;
  position: fixed;
  top: 100%;
  left: 0;
  transition: all 0.3s $default-transition-fn;

  &.open {
    top: 0;
  }

  &__header {
    background: $dark-blue;
    color: $white;
    display: flex;
    align-items: center;
    font-size: $font-xlg;
    height: $header-height;
  }

  &__header-action {
    padding: 1.125rem;
    cursor: pointer;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{2 * $header-height});
    overflow: auto;
    padding: 0 $spacing;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: $white;
    height: $header-height;
    box-shadow: $dark-shadow;
    z-index: 1;
    position: relative;

    .spacer {
      flex: 1;
    }
  }
}

.create-assignment {
  &__body {
    margin: auto;
    max-width: $main-panel-width;

    @include mobile {
      margin: 0;
      max-width: 100%;
    }
  }

  &__radio-button {
    margin-right: $spacing;
    width: 1rem;
    height: 1rem;
  }

  &__option:not(.with-padding) &__radio-button {
    margin: $spacing 0 0 $spacing;
  }

  &__option {
    display: flex;
    cursor: pointer;
    margin: $spacing 0;
    background-color: $white;
    border-radius: $border-radius-std;

    &.with-padding {
      padding: $spacing;
    }

    & &-title {
      font-size: $font-md;
      line-height: 1;
    }
  }

  &__separator {
    color: $grey;
    font-size: $font-lg;
    text-align: center;
    width: 100%;
  }

  &__subtitle {
    margin: $spacing 0;
  }
}
