@import '../../core/mixins';
@import '../../core/variables';

.assignment-analytics {
  &__paper {
    @include paper;
    padding: 0.4375rem 0.875rem;
    margin-bottom: 0.4375rem;
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375em;
  }

  &__submission {
    cursor: pointer;
    width: 100%;
    display: flex;
  }

  &__question {
    background-color: $white;
    padding: 1rem;
    margin: $spacing 0;
  }
}
