@import '../../core/colors';
@import '../../core/mixins';
@import '../../core/variables';

.course-widget {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $home-content-width;
  position: relative;
  box-shadow: $default-shadow;
  padding: 0.75rem;
  background-color: $white;
  border-radius: $border-radius-std;
  line-height: 1.25rem;
  transition: box-shadow 0.3s linear;

  &:hover {
    box-shadow: $spread-shadow;
  }

  &:active {
    outline: 0;
    outline-offset: 0;
    z-index: 1;
  }

  &:focus {
    outline: 2px solid $outline-blue;
    outline-offset: -2px;
    z-index: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    min-height: 1.75em;
    margin-bottom: 0.25rem;
  }

  &__zoomus-icon {
    width: 28px;
    height: 28px;
    margin-right: 0.5rem;
  }

  &__detail {
    font-weight: $font-thick;
    color: $blue;
    display: flex;
    align-items: center;

    &.admin {
      color: $green;
    }
  }

  &__updates {
    color: $orange;
    font-weight: $font-thin;
  }

  &__title {
    @include ellipsis;
    font-weight: $font-thin;
    font-size: $font-md;
    max-width: 100%;
    padding-bottom: 0.4375rem;
  }

  &__admin {
    height: 3.125rem;
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
  }

  &__avatar-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0.5rem;
  }

  &__admin-name {
    font-weight: $font-bold;
  }

  &__meta {
    color: $medium-grey;
    font-weight: $font-thin;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 0.4375rem;
    width: 100%;
    box-sizing: border-box;
  }
}
