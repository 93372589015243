.attendance {
  &__avatar {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 0.4375em;
  }
}

.attendance-help {
  padding: 0 1rem;
}

.attendance-help-cell {
  margin: 1rem 0;

  &__icon {
    margin-right: 0.25rem;
    font-size: 0.875rem;
    width: 1.5rem;
    text-align: center;

    &.sm {
      width: 20px;
      font-size: 0.5rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: $font-xsm;
    font-weight: $font-thick;
    line-height: 1.8;
  }

  &__details {
    line-height: 1.11;
    font-size: $font-sm;
  }
}

.attendance-error-cell {
  animation: attendance-error-cell-in 500ms ease-in forwards;
}

@keyframes attendance-error-cell-in {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}
