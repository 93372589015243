@import '../../core/colors';
@import '../../core/variables';

.class-widget {
  background-color: $white;
  width: 100%;
  cursor: pointer;
  height: $timeline-widget-height;
  border-bottom: 2px solid transparent;
  padding: 0.4375rem 0.875rem;
  display: flex;

  &:hover {
    border-bottom: 2px solid $blue;
  }

  &:focus {
    outline: 2px solid $outline-blue;
    outline-offset: -2px;
    z-index: $z-timeline-widget;
  }

  &:active {
    outline: 0;
    outline-offset: 0;
    z-index: $z-timeline-widget;
  }

  & > .cell-rhs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: $timeline-widget-height;
    flex: 1;
  }

  &__delete-button {
    color: $red;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    font-size: $font-xxlg;
  }

  &__bell-icon {
    margin-right: 0.5rem;
    font-size: 0.8125rem;
  }

  &__videocam-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__comment-bubble {
    margin-left: 0.175rem;
    color: $orange;
    transform: scaleX(0.7);
  }

  &__venue-row,
  &__activities {
    display: flex;
    align-items: center;
  }

  &__no-activities {
    flex: 2.5;
    color: $light-grey;
  }

  &__activity-icon {
    margin-right: 0.4375rem;
    font-size: 1.4875rem;
    position: relative;
    display: flex;
  }

  &__activities-title {
    margin-right: 0.4375rem;
  }

  &__location-icon {
    font-size: 0.8125rem;
    margin-right: 0.5rem;
  }

  &__role {
    color: $orange;
    text-transform: uppercase;
    font-size: 0.625rem;
    margin-top: 0.25rem;
    font-weight: $font-thick;
  }
}

.tutorial-widget {
  display: flex;
  height: $timeline-widget-height;
  cursor: pointer;
  padding: 0.4375rem 0.875rem;
  width: 100%;

  &__rhs {
    padding-left: 1.3125rem;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 0.4375em;
  }

  &__time {
    display: flex;
    align-items: center;
    margin-bottom: 0.4375rem;
  }
}

.lecture {
  &__title {
    display: flex;
    flex-wrap: wrap;
    text-overflow: ellipsis;

    &__text-attr {
      font-weight: $font-thick;
      margin-bottom: 0.4375rem;
      max-height: 1.3125rem;
      line-height: 1.3125;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
    }
  }

  &__timings-row-attrs {
    display: flex;
    align-items: center;
    font-weight: $font-thin;
    margin-bottom: 0.4375rem;
    height: 1.3125rem;
  }
}
