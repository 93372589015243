#error-dialog {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.3);
}

#error-dialog > #error-dialog-box {
  width: 35em;
  background-color: white;
  border-radius: 0.3em;
  padding: 0.5em 1em;
}

#error-dialog > #error-dialog-box > .actions {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}

:root {
  --blue: #0b68ad;
}

#error-dialog > #error-dialog-box > .actions > button {
  border: none;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: none;
  padding: 0.5rem 1rem;
  color: var(--blue);
}

#error-dialog > #error-dialog-box > .actions > button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

#error-dialog > #error-dialog-box > #thanks {
  display: none;
}
